<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'manage instance: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      header-class="text-center"
      title="Content:"
      style="background-color: lightblue; margin: 2px;">
      <b-form-group
        label-cols-md="1"
        label="Key:"
        label-align-md="right"
        label-for="key">
        <b-form-input id="key" v-model="formData.key" disabled></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Type:"
        label-align-sm="right"
        label-for="type">
        <b-form-select
          id="type"
          v-model="formData.typeSelected"
          :options="mapCatalog2Select(contentTypeCat)"
          :disabled="!!formData.key"/>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Title:"
        label-align-sm="right"
        label-for="title">
        <b-form-input id="title" v-model="formData.title"></b-form-input>
      </b-form-group>
      <gl-text v-if="formData.typeSelected === CONTENT_TYPE_DEFS.TEXT_ONLY"
        ref="contentTextRef" title="Body:" />
      <gl-image v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.IMAGE_ONLY"
        ref="contentImageRef" />
      <gl-comm-product
        v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.COMM_PRODUCT"
        :commProductCtgyTypeCat="commProductCtgyTypeCat"
        :findContentByKey="findContentByKey"
        :initialData="formData.commProductData"
        ref="commProductRef" />
      <gl-blog-post
        v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.BLOG_POST"
        :findContentByKey="findContentByKey"
        :initialData="formData.blogPostData"
        ref="blogPostRef" />
      <gl-article
        v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.ARTICLE"
        :findContentByKey="findContentByKey"
        :initialData="formData.articleData"
        ref="articleRef" />
      <gl-event
        v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.EVENT"
        :findContentByKey="findContentByKey"
        :initialData="formData.eventData"
        ref="eventRef" />
      <gl-donation
        v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.DONATION"
        :findContentByKey="findContentByKey"
        :initialData="formData.donationData"
        ref="donationRef" />
      <gl-comm-ad
        v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.COMM_AD"
        :findContentByKey="findContentByKey"
        :initialData="formData.commAdData"
        ref="commAdRef" />
      <gl-pdf-doc v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.PDF_DOC"
        :initialData="formData.pdfDocData" ref="pdfDocRef" />
      <gl-payment-widget
        v-else-if="formData.typeSelected === CONTENT_TYPE_DEFS.PAYMENT_WIDGET"
        :initialData="formData.paymentWidgetData" ref="paymentWidgetRef" />
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleUpload()">Upload</b-button>
          <b-button @click="handleClearContent()">Clear</b-button>
        </div>
      </template>
    </b-card>
    <b-card
      header-class="text-center"
      title="uploaded content"
      style="background-color: lightblue; margin: 2px;">
      <b-col lg="7" class="mb-2" style="padding: 0px;">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="formData.table.filter"
            type="search"
            placeholder="Filter on title, type or create date. Use &amp; to combine terms.">
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!formData.table.filter" @click="formData.table.filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-table
        sticky-header
        :fields="fieldsDefs"
        :items="content"
        :filter-included-fields="['fragments.title', 'typeCode', 'createDate']"
        :filter="formData.table.filter"
        :filter-function="customFilterFn"
        :busy="loading">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <a @click="handleLoadContent(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="upload" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">load</span>
          </a>
          <a @click="handleCopyContentKey(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="files" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">copy key</span>
          </a>
          <a @click="handleDeleteContent(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="trash" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">delete</span>
          </a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
// TODO: just for test! REMOVE IT!
// import axios from 'axios'
import config from '@/config'
import '@/plugins/bootstrap-vue-dash'
import { mapState } from 'vuex'

import * as layoutSvc from '@/services/utils/layout'
import { CONTENT_TYPE_DEFS } from '@/services/glapi/constants'

import GlText from '@/components/content/GlText'
import GlImage from '@/components/content/GlImage'
import GlCommProduct from '@/components/content/GlCommProduct'
import GlBlogPost from '@/components/content/GlBlogPost'
import GlArticle from '@/components/content/GlArticle'
import GlEvent from '@/components/content/GlEvent'
import GlDonation from '@/components/content/GlDonation'
import GlCommAd from '@/components/content/GlCommAd'
import GlPdfDoc from '@/components/content/GlPdfDoc'

import { actions as glapiActionsNames,
  states as glapiStNames,
  moduleNamespace as glapiModuleNSp,
  getNamespacedName } from '@/store/modules/glapi/names'

import {
  states as accountStNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'manage-inst-content',
  version: '0.0.4',
  data () {
    return {
      CONTENT_TYPE_DEFS,
      loading: false,
      // TODO: all form data in one formData object
      instKey: '',
      formData: {
        glapiId: '',
        typeSelected: CONTENT_TYPE_DEFS.TEXT_ONLY,
        key: '',
        title: '',
        commProductData: null,
        blogPostData: null,
        articleData: null,
        eventData: null,
        donationData: null,
        commAdData: null,
        pdfDocData: null,
        paymentWidget: null,
        table: {
          filter: ''
        }
      },
      fieldsDefs: [
        {
          key: 'fragments.title',
          label: 'Title'
        },
        {
          key: 'key',
          label: 'Key'
        },
        {
          key: 'typeCode',
          label: 'Type'
        },
        {
          key: 'lastUpdated',
          label: 'Last updated'
        },
        {
          key: 'createDate',
          label: 'Create date'
        },
        {
          key: 'actions',
          label: 'Actions',
          stickyColumn: true
        }
      ]
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      const _startTime = performance.now()
      console.log('PERF-REMOVE: ManageInstContent.mounted _startTime: ', _startTime)
      // const _perfMark = 'ManageinstContent.mounted'
      // window.performance.mark(_perfMark)
      console.log('PERF-REMOVE: ManageInstContent.mounted FETCH_CONTENT-1: ',
        performance.now() - _startTime, performance.now())
      await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_CONTENT))
      console.log('PERF-REMOVE: ManageInstContent.mounted FETCH_CONTENT-2: ',
        performance.now() - _startTime, performance.now())
      const _foundInst = this.instances.find(e => e._id === this.$route.params.instId)
      if (_foundInst) {
        this.instKey = _foundInst.instKey
      } else {
        // console.log('WARN-REMOVE: no key found for the instId: ', this.$route.params.instId)
      }
      console.log('PERF-REMOVE: ManageInstContent.mounted FETCH_CONTENT_TYPE_CAT-1: ',
        performance.now() - _startTime, performance.now())
      await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_CONTENT_TYPE_CAT))
      console.log('PERF-REMOVE: ManageInstContent.mounted FETCH_CONTENT_TYPE_CAT-2: ',
        performance.now() - _startTime, performance.now())
      console.log('PERF-REMOVE: ManageInstContent.mounted FETCH_COMM_PRODUCT_CTGY_TYPE_CAT-1: ',
        performance.now() - _startTime, performance.now())
      await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_COMM_PRODUCT_CTGY_TYPE_CAT))
      console.log('PERF-REMOVE: ManageInstContent.mounted FETCH_COMM_PRODUCT_CTGY_TYPE_CAT-2: ',
        performance.now() - _startTime, performance.now())
      // this.formData.commProduct.categorySelected = this.commProductCtgyTypeCat[0]
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
      // performance.clearMarks()
      // performance.clearMeasures()
    }
  }, // mounted
  computed: {
    ...mapState(glapiModuleNSp, [
      [glapiStNames.CONTENT_TYPE_CAT],
      [glapiStNames.CONTENT],
      [glapiStNames.COMM_PRODUCT_CTGY_TYPE_CAT]
    ]),
    ...mapState(accountModuleNSp, [
      [accountStNames.INSTANCES],
      [accountStNames.AUTH_INFO],
      [accountStNames.ACCNT_DETAILS]
    ])
  }, // computed
  methods: {
    async handleUpload () {
      try {
        this.loading = true
        const _content = {}
        _content['fragments'] = {}
        if (this.formData.typeSelected === CONTENT_TYPE_DEFS.TEXT_ONLY) {
          if (!this.$refs.contentTextRef.hasContent()) {
            this.$bvToast.toast('Please enter some content!')
            return
          }
          _content['fragments']['body'] = this.$refs.contentTextRef.getJSON()
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.IMAGE_ONLY) {
          // _content['fragments']['imgFile'] = await this.$refs.contentImageRef.getImageSrc()
          const _imgSrc = { imgFile: this.$refs.contentImageRef.getImageSrc() }
          const _imgDim = this.$refs.contentImageRef.getImageDimentions()
          _content['fragments'] = { ..._imgSrc, ..._imgDim }
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.COMM_PRODUCT) {
          Object.assign(_content['fragments'], this.$refs.commProductRef.getFormData())
          _content['jsonld'] = _content['fragments']['structData']
          delete _content['fragments']['structData']
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.BLOG_POST) {
          Object.assign(_content['fragments'], this.$refs.blogPostRef.getFormData())
          _content['jsonld'] = _content['fragments']['structData']
          delete _content['fragments']['structData']
          _content['author'] = this.accntDetails.authorName || this.authInfo.nickname
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.ARTICLE) {
          Object.assign(_content['fragments'], this.$refs.articleRef.getFormData())
          _content['jsonld'] = _content['fragments']['structData']
          delete _content['fragments']['structData']
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.EVENT) {
          Object.assign(_content['fragments'], this.$refs.eventRef.getFormData())
          _content['jsonld'] = _content['fragments']['structData']
          delete _content['fragments']['structData']
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.DONATION) {
          Object.assign(_content['fragments'], this.$refs.donationRef.getFormData())
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.COMM_AD) {
          Object.assign(_content['fragments'], this.$refs.commAdRef.getFormData())
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.PDF_DOC) {
          // Object.assign(_content['fragments'], this.$refs.commAdRef.getFormData())
          let _formData = new FormData()
          console.log('TRACE-REMOVE: ManageInstContent.handleUpload: getFile is: ',
            this.$refs.pdfDocRef.getFile())
          _formData.append('file', this.$refs.pdfDocRef.getFile())
          console.log('TRACE-REMOVE: ManageInstContent.handleUpload: _formData is: ', _formData)
          _content['formData'] = _formData
          _content['fragments']['signAreaList'] = this.$refs.pdfDocRef.getSignAreaList()
        } else if (this.formData.typeSelected === CONTENT_TYPE_DEFS.PAYMENT_WIDGET) {
          _content['fragments']['pmList'] = this.$refs.paymentWidgetRef.getPMList()
        }
        _content['fragments']['title'] = this.formData.title
        if (this.formData.key !== '') {
          _content['key'] = this.formData.key
          // TODO: check why is this here?! accntId must be always present
          _content['_id'] = this.formData.glapiId
        } // TODO: what if not available? Exception should be thrown!
        _content['instKey'] = this.instKey
        _content['typeCode'] = this.formData.typeSelected
        await this.$store.dispatch(getNamespacedName(glapiActionsNames.UPLOAD_CONTENT), _content)
        this.handleClearContent()
        this.$bvToast.toast('Content successfully uploaded!')
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
      } finally {
        this.loading = false
      }
    }, // handleUpload
    handleClearContent () {
      // TODO: consider if it is necessary
      this.formData.glapiId = null
      this.formData.key = ''
      this.formData.typeSelected = CONTENT_TYPE_DEFS.TEXT_ONLY
      this.formData.title = ''
      this.formData.commProductData = null
      this.formData.blogPostData = null
      this.formData.articleData = null
      this.formData.eventData = null
      this.formData.donationData = null
      this.formData.commAdData = null
      this.formData.pdfDocData = null
      this.formData.paymentWidgetData = null
      /* ----- textOnly ----- */
      this.$nextTick(() => {
        this.$refs.contentTextRef.clearContent()
      })
    }, // handleClearContent
    handleLoadContent (contentItem) {
      const _typeChanged = this.formData.typeSelected !== contentItem.typeCode
      // this.formData.typeSelected = contentItem.typeCode
      this.formData.title = contentItem.fragments.title
      this.formData.glapiId = contentItem._id // TODO: wtf?
      this.formData.key = contentItem.key
      if (contentItem.typeCode === CONTENT_TYPE_DEFS.TEXT_ONLY) {
        if (_typeChanged) {
          this.$nextTick(() => {
            this.$refs.contentTextRef.setContent(contentItem.fragments.body)
          })
        } else {
          this.$refs.contentTextRef.setContent(contentItem.fragments.body)
        }
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.IMAGE_ONLY) {
        if (_typeChanged) {
          this.$nextTick(() => {
            // this.$refs.contentImageRef.setImageSrc(contentItem.fragments.imgUrl)
            this.$refs.contentImageRef.setImageFragments(contentItem.fragments)
          })
        } else {
          // this.$refs.contentImageRef.setImageSrc(contentItem.fragments.imgUrl)
          this.$refs.contentImageRef.setImageFragments(contentItem.fragments)
        }
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.COMM_PRODUCT) {
        this.formData.commProductData = Object.assign({}, contentItem.fragments)
        this.formData.commProductData['structData'] = contentItem.jsonld
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.BLOG_POST) {
        this.formData.blogPostData = Object.assign({}, contentItem.fragments)
        this.formData.blogPostData['structData'] = contentItem.jsonld
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.ARTICLE) {
        this.formData.articleData = Object.assign({}, contentItem.fragments)
        this.formData.articleData['structData'] = contentItem.jsonld
        console.log('TRACE-REMOVE: ManageInst.handleLoadContent: contentItem.jsonld is: ', contentItem.jsonld)
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.EVENT) {
        this.formData.eventData = Object.assign({}, contentItem.fragments)
        this.formData.eventData['structData'] = contentItem.jsonld
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.DONATION) {
        // this.formData.donationData = Object.assign({}, contentItem.fragments)
        // this.$set(this.formData, 'donationData', contentItem.fragments)
        this.$nextTick(() => this.$refs.donationRef.setContent(contentItem.fragments))
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.COMM_AD) {
        this.formData.commAdData = Object.assign({}, contentItem.fragments)
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.PDF_DOC) {
        let _pdfDocData = {}
        _pdfDocData.title = contentItem.fragments.title
        _pdfDocData.pdfSrc = config.glapiBaseUri + '/content/pdfUploads/' +
          this.instKey + '/' + contentItem.fragments.pdfFileKey + '.pdf'
        _pdfDocData.signAreaList = contentItem.fragments.signAreaList
        // this.$set(this.formData, 'pdfDocData', _pdfDocData)
        this.$set(this.formData, 'pdfDocData', {})
        this.$set(this.formData.pdfDocData, 'title', _pdfDocData.title)
        this.$set(this.formData.pdfDocData, 'pdfSrc', _pdfDocData.pdfSrc)
        this.$set(this.formData.pdfDocData, 'signAreaList', _pdfDocData.signAreaList)
        console.log('TRACE-REMOVE: ManageInstContent.handleLoadContent: pdfSrc is: ', this.formData.pdfDocData.pdfSrc)
      } else if (contentItem.typeCode === CONTENT_TYPE_DEFS.PAYMENT_WIDGET) {
        // this.formData.paymentWidgetData = Object.assign({}, contentItem.fragments)
        this.$set(this.formData, 'paymentWidgetData', {})
        this.$set(this.formData.paymentWidgetData, 'pmList', contentItem.fragments.pmList)
      }
      this.formData.typeSelected = contentItem.typeCode
    }, // handleLoadContent
    async handleDeleteContent (contentItem) {
      try {
        console.log('TRACE-REMOVE: contentItem is: ', contentItem)
        const _confirmMsg = 'Are you sure you want to delete ' +
          contentItem.typeCode + ' item with key: ' + contentItem.key + '?'
        if (await this.$bvModal.msgBoxConfirm(_confirmMsg)) {
          this.loading = true
          await this.$store.dispatch(getNamespacedName(glapiActionsNames.ERASE_CONTENT), contentItem)
        } else {
          return
        }
        this.$bvToast.toast('The content was succesfully deleted!')
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
      } finally {
        this.loading = false
      }
    }, // handleDeleteContent
    async handleCopyContentKey (contentItem) {
      try {
        await navigator.clipboard.writeText(contentItem.key)
        this.$bvToast.toast('Content key copied in clipboard!')
      } catch (e) {
        this.$bvToast.toast('Error copying the content key in clipboard!')
      }
    }, // handleCopyContentKey
    /* ----- utility methods ----- */
    mapCatalog2Select: layoutSvc.mapCatalog2Select,
    findContentByKey (k) {
      return this.content.find(el => el.key === k)
    }, // findContentByKey
    escapeRegex (str) { // TODO: doesn't seem to work
      // eslint-disable-next-line no-useless-escape
      return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    }, // escapeRegex
    customFilterFn (rec) {
      // return !this.formData.table.filter || JSON.stringify(rec).includes(this.escapeRegex(this.formData.table.filter))
      const recStr = JSON.stringify(rec['fragments']['title']) +
        JSON.stringify(rec['typeCode']) + JSON.stringify(rec['createDate'])
      let matched = true
      if (this.formData.table.filter) {
        for (const match of this.formData.table.filter.split('&')) {
          if (!JSON.stringify(recStr).includes(match)) {
            matched = false
            break
          }
        }
      }
      return matched
    } // customFilterFn
  }, // methods
  components: {
    GlText,
    GlImage,
    GlCommProduct,
    GlBlogPost,
    GlArticle,
    GlEvent,
    GlDonation,
    GlCommAd,
    GlPdfDoc,
    GlPaymentWidget: () => import('@/components/content/GlPaymentWidget')
    // GlPdfDoc: () => import('@/components/content/GlPdfDoc')
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
.cmpn-actions {
  padding-left: 10px;
}
</style>
