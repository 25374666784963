<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'manage instance: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      v-if="formData.key"
      header-class="text-center"
      title="Content:"
      style="background-color: lightblue; margin: 2px;">
      <b-form-group
        label-cols-md="1"
        label="Key:"
        label-align-md="right"
        label-for="key">
        <b-form-input id="key" v-model="formData.key" disabled></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Name / EMail:"
        label-align-sm="right"
        label-for="title">
        <b-form-row>
          <b-col md="4">
            <b-form-input id="name" v-model="formData.name" disabled></b-form-input>
          </b-col>
          <b-col md="4">
            <b-form-input id="email" v-model="formData.email" disabled></b-form-input>
          </b-col>
        </b-form-row>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Document:"
        label-align-sm="right"
        label-for="pdfDoc">
        <pdf-viewer :fileUrl="formData.docUrl" :signAreaList="formData.signAreaList" />
      </b-form-group>
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button :disabled="true" @click="handleUpload()">Upload</b-button>
          <b-button @click="handleClearLoaded()">Clear</b-button>
        </div>
      </template>
    </b-card>
    <b-card
      header-class="text-center"
      title="uploaded content"
      style="background-color: lightblue; margin: 2px;">
      <b-col lg="7" class="mb-2" style="padding: 0px;">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="formData.table.filter"
            type="search"
            placeholder="Filter on title, type or create date. Use &amp; to combine terms.">
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!formData.table.filter" @click="formData.table.filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-table
        sticky-header
        :fields="fieldsDefs"
        :items="docs"
        :filter-included-fields="['name', 'email', 'createDate']"
        :filter="formData.table.filter"
        :filter-function="customFilterFn"
        :busy="loading">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <a @click="handleLoadDocs(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="upload" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">load</span>
          </a>
          <a @click="handleCopyContentKey(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="files" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">copy key</span>
          </a>
          <a @click="handleDeleteContent(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="trash" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">delete</span>
          </a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { mapState } from 'vuex'

import config from '@/config'
import * as layoutSvc from '@/services/utils/layout'
import { CONTENT_TYPE_DEFS } from '@/services/glapi/constants'

import { actions as glapiActionsNames,
  states as glapiStNames,
  moduleNamespace as glapiModuleNSp,
  getNamespacedName } from '@/store/modules/glapi/names'

import {
  states as accountStNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

function __getDefaultFormData () {
  return {
    key: null,
    title: null,
    docUrl: null,
    signAreaList: [],
    table: { filter: '' }
  }
}
export default {
  name: 'manage-inst-docs',
  version: '0.0.1',
  data () {
    return {
      CONTENT_TYPE_DEFS,
      loading: false,
      // TODO: all form data in one formData object
      instKey: '',
      formData: __getDefaultFormData(),
      fieldsDefs: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'key',
          label: 'Key'
        },
        {
          key: 'email',
          label: 'EMail'
        },
        {
          key: 'lastUpdated',
          label: 'Last updated'
        },
        {
          key: 'createDate',
          label: 'Create date'
        },
        {
          key: 'actions',
          label: 'Actions',
          stickyColumn: true
        }
      ]
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      const _foundInst = this.instances.find(e => e._id === this.$route.params.instId)
      if (_foundInst) {
        this.instKey = _foundInst.instKey
      } else {
        // console.log('WARN-REMOVE: no key found for the instId: ', this.$route.params.instId)
      }
      console.log('TRACE-REMOVE: ManageInstDocs: _foundInst is: ', _foundInst)
      await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_DOCS), this.instKey)
      console.log('TRACE-REMOVE: ManageInstDocs: this.docs is: ', this.docs)
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(glapiModuleNSp, [
      [glapiStNames.DOCS]
    ]),
    ...mapState(accountModuleNSp, [
      [accountStNames.INSTANCES]
    ])
  }, // computed
  methods: {
    async handleUpload () {
    }, // handleUpload
    handleClearLoaded () {
      console.log('TRACE-REMOVE: ManageInstDocs.handleClearLoaded!')
      this.formData.key = null
      this.formData.name = null
      this.formData.email = null
      this.formData.docUrl = null
      this.formData.signAreaList = []
    }, // handleClearLoaded
    handleLoadDocs (docsItem) {
      console.log('TRACE-REMOVE: ManageInstDocs.handleLoadDocs: this.formData.key is: ', this.formData.key)
      if (this.formData.key) {
        this.handleClearLoaded()
      }
      this.$nextTick(() => {
        this.formData.name = docsItem.name
        this.formData.email = docsItem.email
        this.formData.key = docsItem.key
        this.formData.docUrl = config.glapiBaseUri + '/docs/' +
          this.instKey + '/signDocUploads/' + docsItem.pdfFileKey + '.pdf'
        console.log('TRACE-REMOVE: ManageInstDocs.handleLoadDocs: this.formData.docUrl is: ', this.formData.docUrl)
        this.formData.signAreaList = docsItem.signAreaList
      })
    }, // handleLoadDocs
    async handleDeleteContent (contentItem) {
      try {
        console.log('TRACE-REMOVE: contentItem is: ', contentItem)
        const _confirmMsg = 'Are you sure you want to delete ' +
          contentItem.typeCode + ' item with key: ' + contentItem.key + '?'
        if (await this.$bvModal.msgBoxConfirm(_confirmMsg)) {
          this.loading = true
          await this.$store.dispatch(getNamespacedName(glapiActionsNames.ERASE_CONTENT), contentItem)
        } else {
          return
        }
        this.$bvToast.toast('The content was succesfully deleted!')
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
      } finally {
        this.loading = false
      }
    }, // handleDeleteContent
    async handleCopyContentKey (contentItem) {
      try {
        await navigator.clipboard.writeText(contentItem.key)
        this.$bvToast.toast('Content key copied in clipboard!')
      } catch (e) {
        this.$bvToast.toast('Error copying the content key in clipboard!')
      }
    }, // handleCopyContentKey
    /* ----- utility methods ----- */
    mapCatalog2Select: layoutSvc.mapCatalog2Select,
    findContentByKey (k) {
      return this.content.find(el => el.key === k)
    }, // findContentByKey
    escapeRegex (str) { // TODO: doesn't seem to work
      // eslint-disable-next-line no-useless-escape
      return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    }, // escapeRegex
    customFilterFn (rec) {
      // return !this.formData.table.filter || JSON.stringify(rec).includes(this.escapeRegex(this.formData.table.filter))
      const recStr = JSON.stringify(rec['name']) +
        JSON.stringify(rec['email']) + JSON.stringify(rec['createDate'])
      let matched = true
      if (this.formData.table.filter) {
        for (const match of this.formData.table.filter.split('&')) {
          if (!JSON.stringify(recStr).includes(match)) {
            matched = false
            break
          }
        }
      }
      return matched
    } // customFilterFn
  }, // methods
  components: {
    PdfViewer: () => import('@/components/3rdParty/mozilla/PdfViewer')
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
.cmpn-actions {
  padding-left: 10px;
}
</style>
<style lang="scss">
@import "@/assets/style/scss/main.scss";
</style>
