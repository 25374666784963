<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'account: ' + glapiId"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      header-class="text-center"
      title="account data:"
      style="background-color: lightblue; margin: 2px;">
      <b-form-group
        label-cols-md="1"
        label="author name:"
        label-align-md="right"
        label-for="authorName">
        <b-form-input id="authorName" v-model="formData.authorName"></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-md="1"
        label="notification alias:"
        label-align-md="right"
        label-for="notifAlias">
        <b-form-input id="notifAlias" v-model="formData.notifAlias"></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-md="1"
        label="email:"
        label-align-md="right"
        label-for="email">
        <b-form-input id="email" v-model="authInfo.email" disabled></b-form-input>
      </b-form-group>
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleAccntUpload()">Upload</b-button>
        </div>
      </template>
    </b-card>
    <b-card
      v-if="orgaLoaded"
      header-class="text-center"
      title="organization details:"
      style="background-color: lightblue; margin: 2px;">
      <gl-orga ref="glOrgaRef" :initialData="orgaLoaded" />
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleUpload()">Upload</b-button>
          <b-button @click="handleClear()">Clear</b-button>
        </div>
      </template>
    </b-card>
    <b-card
      header-class="text-left"
      title="my organizations:"
      style="background-color: lightblue; margin: 2px;">
      <template #header>
        <b-button @click="handleCreate()">Create</b-button>
      </template>
      <b-table
        sticky-header
        :fields="fieldsDefs"
        :items="orgaList"
        :busy="loading">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <a @click="handleLoadOrga(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="upload" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">load</span>
          </a>
          <a @click="handleCopyOrderKey(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="files" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">copy key</span>
          </a>
          <a @click="handleDeleteOrder(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="trash" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">delete</span>
          </a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { mapState, mapActions } from 'vuex'
import GlOrga from '@/components/orga/GlOrga'

import {
  states as accountStNames,
  actions as accountActionNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'account-settings',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      orgaLoaded: false,
      // instKey: '',
      formData: {
        glapiId: '',
        authInfo: {},
        notifAlias: undefined
      },
      fieldsDefs: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'key',
          label: 'Key'
        },
        {
          key: 'url',
          label: 'Web'
        },
        {
          key: 'createDate',
          label: 'Create date'
        },
        {
          key: 'actions',
          label: 'Actions',
          stickyColumn: true
        }
      ]
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      // this.formData.nickname = this.authInfo.nickname
      this.formData.authorName = this.accntDetails.authorName
      this.formData.notifAlias = this.accntDetails.notifAlias
      await this.accntDispatchFetchOrgaList()
      console.log('TRACE-REMOVE: AccountSettings.mounted: orgaList is: ', this.orgaList)
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(accountModuleNSp, [
      [accountStNames.GLAPI_ID],
      [accountStNames.AUTH_INFO],
      [accountStNames.ORGA_LIST],
      [accountStNames.ACCNT_DETAILS]
    ])
  }, // computed
  methods: {
    /* ----- mapped action methods ----- */
    ...mapActions(accountModuleNSp,
      {
        accntDispatchFetchOrgaList: accountActionNames.FETCH_ORGA_LIST,
        accntDispatchSaveOrga: accountActionNames.SAVE_ORGA,
        accntDispatchEraseOrga: accountActionNames.ERASE_ORGA,
        accntDispatchSaveAccntDetails: accountActionNames.SAVE_ACCNT_DETAILS
      }),
    /* ----- utility methods ----- */
    handleCreate () {
      this.orgaLoaded = {}
    }, // handleCreate
    async handleAccntUpload () {
      try {
        // this.loading = true
        console.log('TRACE-REMOVE: AccountSettings.handleAccountUpload: this.formData.authorName is: ',
          this.formData.authorName)
        // await this.accntDispatchSaveOrga(this.$refs.glOrgaRef.formData)
        await this.accntDispatchSaveAccntDetails({
          authorName: this.formData.authorName,
          notifAlias: this.formData.notifAlias === '' ? null : this.formData.notifAlias
        })
        // this.handleClear()
        this.$bvToast.toast('Upload Account success!')
      } catch (e) {
        this.$bvToast.toast('Error uploading orga!')
        console.log('ERROR-REMOVE: AccountSettings.handleUpload: error is: ', e)
      } finally {
        // this.loading = false
      }
    }, // handleUpload
    async handleUpload () {
      try {
        // this.loading = true
        console.log('TRACE-REMOVE: AccountSettings.handleUpload: this.$refs.glOrgaRef.formData is: ', this.$refs.glOrgaRef.formData)
        await this.accntDispatchSaveOrga(this.$refs.glOrgaRef.formData)
        this.handleClear()
        this.$bvToast.toast('Upload success!')
      } catch (e) {
        this.$bvToast.toast('Error uploading orga!')
        console.log('ERROR-REMOVE: AccountSettings.handleUpload: error is: ', e)
      } finally {
        // this.loading = false
      }
    }, // handleUpload
    handleClear () {
      this.orgaLoaded = false
    }, // handleClear
    async handleLoadOrga (orgaItem) {
      this.orgaLoaded = orgaItem
    }, // handleLoadOrder
    async handleDeleteOrder (item) {
      try {
        await this.accntDispatchEraseOrga(item)
        this.$bvToast.toast('Delete success!')
      } catch (e) {
        this.$bvToast.toast('Error deleting orga!')
      }
    }, // handleDeleteOrder
    async handleCopyOrderKey (item) {
      try {
        await navigator.clipboard.writeText(item.key)
        this.$bvToast.toast('Content key copied in clipboard!')
      } catch (e) {
        this.$bvToast.toast('Error copying the content key in clipboard!')
      }
    } // handleCopyOrderKey
    /* ----- utility methods ----- */
  }, // methods
  components: {
    GlOrga
  } // components
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/scss/main.scss";
.cmpn {
  margin-top: 85px;
}
.cmpn-actions {
  padding-left: 10px;
}
</style>
