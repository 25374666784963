<template>
  <b-form-group label-cols-sm="1" label="Image:" label-align-sm="right">
    <b-alert
      v-model="imgAdjustedWarn"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="warning"
      dismissible>
      Caution! Your image ({{ origWidth }}x{{ origHeight }} pixels) has been adjusted on the screen, because is not fitting in the recommended 320x240 pixels. Please crop it, otherwise it could apper outside the frames in your geekline component.
    </b-alert>
    <b-form-checkbox v-model="cropping" switch>
      Crop the image (w/h --> {{ origWidth }}x{{ origHeight }})
    </b-form-checkbox>
    <img
      v-if="!cropping"
      id="contentImage"
      :src="imageSrc"
      ref="contentImageRef"
      :style= "imgStyle"
      @click="$refs.file.click()"
      @load="handleImgLoad($event)"/>
    <div v-else style="margin: 0 0;">
      original width / height: {{ origWidth }}, {{ origHeight }} <br />
      cropped width / height: {{ croppedWidth }}, {{ croppedHeight }}
      <!-- :boundary="{ height: 300 }" -->
      <gl-cropper
        ref="croppieRef"
        :enableOrientation="true"
        @result="cropResult"
        @update="cropUpdate" />

      <button @click="cropRotate(-90)">rotate left</button>
      <button @click="saveCroppedResult()">save result</button>
    </div>
    <input ref="file" type="file" hidden @change="handleLoadFile($event)"/>
  </b-form-group>
</template>

<script>
import GlCropper from '@/components/img/GlCropper.vue'

const NO_IMAGE_AVAILABLE = require('@/assets/content/no_img_available-2-320x240.jpg')

const MAX_WIDTH_STYLE = 'max-width: 320px; height: auto;'
const MAX_HEIGHT_STYLE = 'width: auto; max-height: 240px;'

export default {
  name: 'gl-image',
  version: '0.0.1',
  data () {
    return {
      imgAdjustedWarn: false,
      imageSrc: NO_IMAGE_AVAILABLE,
      imgStyle: MAX_WIDTH_STYLE,
      cropping: false,
      cropped: null,
      croppedWidth: 320,
      croppedHeight: 240,
      origWidth: 0,
      origHeight: 0
    }
  }, // data
  watch: {
    cropping: function (v) {
      if (v) {
        this.$nextTick(() => {
          this.$refs.croppieRef.bind({
            url: this.imageSrc,
            zoom: 0
          })
        })
      }
    }, // cropping
    imageSrc: function (v) {
      let _img = new Image()
      _img.onload = () => {
        this.origWidth = _img.width
        this.origHeight = _img.height
        if (_img.height && _img.width && (_img.height > 240) &&
          ((_img.height / 240) > (_img.width / 320))) {
          this.imgStyle = MAX_HEIGHT_STYLE
        } else {
          this.imgStyle = MAX_WIDTH_STYLE
        }
        console.log('TRACE-REMOVE: GLImage.imageSrc watch: ', _img.height, _img.width)
        if (_img.height > 240 || _img.width > 320) {
          this.imgAdjustedWarn = true
        }
      }
      _img.src = v
    } // imageSrc
  }, // watch
  methods: {
    /* ----- public methods ----- */
    getImageSrc () {
      return this.imageSrc
    }, // getImageSrc
    getImageDimentions () {
      return { imgWidth: this.$refs.contentImageRef.width,
        imgHeight: this.$refs.contentImageRef.height }
    }, // getImageDimentions
    setImageFragments (imgFrag) {
      const _imgHeight = imgFrag.imgHeight
      const _imgWidth = imgFrag.imgWidth
      if (_imgHeight && _imgWidth && (_imgHeight > 240) &&
        ((_imgHeight / 240) > (_imgWidth / 320))) {
        this.imgStyle = MAX_HEIGHT_STYLE
      } else {
        this.imgStyle = MAX_WIDTH_STYLE
      }
      if (_imgHeight && _imgWidth && ((_imgHeight > 240) || (_imgWidth > 320))) {
        this.imgAdjustedWarn = true
      }
      this.imageSrc = imgFrag.imgUrl
    }, // setImageSrc
    /* ----- private methods ----- */
    handleLoadFile (e) {
      var reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      // var _file = e.target.files[0]
      reader.onload = (e) => {
        this.imageSrc = e.target.result
      }
    }, // handleLoadFile
    handleImgLoad (e) {
      console.log('TRACE-REMOVE: GlImage.handleImgLoad: e is: ', e)
    }, // handleImgLoad
    cropResult (output) {
      this.cropped = output
      console.log('TRACE-REMOVE: AppDev.result: output is: ', output)
    }, // cropResult
    cropUpdate (val) {
      console.log(val)
      this.croppedWidth = Math.floor((val.points[2] - val.points[0]) * val.zoom)
      this.croppedHeight = Math.floor((val.points[3] - val.points[1]) * val.zoom)
    }, // cropUpdate
    cropRotate (rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle)
    }, // cropRotate
    async saveCroppedResult () {
      this.imageSrc = await this.$refs.croppieRef.result()
      this.cropping = false
    } // saveCroppedResult
  }, // methods
  components: {
    GlCropper
  }
}
</script>

<style lang="scss" scoped>
</style>
