<template>
  <div>
    <b-form-group
      label-cols-sm="1"
      label="Structured data (json-ld):"
      label-align-sm="right"
      label-for="structData">
      <b-form-textarea
        id="structData"
        v-model="formData.structData"
        placeholder="Enter some structured data ..."
        rows="2"
        max-rows="3" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Category:"
      label-align-md="right"
      label-for="commProductCtgy">
      <b-form-select
        id="commProductCtgy"
        v-model="formData.categorySelected"
        :options="mapCatalog2Select(commProductCtgyTypeCat)" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Tags / List order:"
      label-align-md="right"
      label-for="tags">
      <b-form-row>
        <b-col md="6">
          <b-form-tags input-id="tags" v-model="formData.tags" :limit="5"
            :tag-validator="isValidTag" placeholder="Enter up to 20 chars, separated by space / enter"
            separator=" " tag-pills tag-variant="primary" remove-on-delete></b-form-tags>
        </b-col>
        <b-col md="6">
          <b-form-input id="listOrder" v-model="formData.listOrder" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-modal
      id="modal-ai-descr"
      ref="modalAIDescr"
      title="AI Product description"
      @ok="handleAIDescrModalOk">
      <form ref="form" @submit.stop.prevent="handleAIDescrModalSubmit">
        <b-form-group
          label="CommProduct name"
          label-for="cp-name-input"
          invalid-feedback="Product Name is required"
          :state="modalAIDescr.stateValid">
          <b-form-input
            id="cp-name-input"
            v-model="modalAIDescr.commProdName"
            :state="modalAIDescr.stateValid"
            placeholder="Enter the name of the product"
            required></b-form-input>
        </b-form-group>
        <b-form-group
          label="Focus features (comma separated)"
          label-for="cp-focus-features-input">
          <b-form-input
            id="cp-focus-features-input"
            v-model="modalAIDescr.commProdFF"
            placeholder="Enter features to focus on"></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <b-form-group
      label-cols-md="1"
      label="Description:"
      label-align-md="right"
      label-for="ai-descr">
      <!-- <b-form-checkbox id="voucher" v-model="formData.voucher" switch /> -->
      <b-button v-if="!aiDescrLoading" v-b-modal.modal-ai-descr>Ask AI</b-button>
      <b-button v-if="aiDescrLoading">
        <b-spinner small type="grow"></b-spinner>
        Loading...
      </b-button>
    </b-form-group>
    <gl-text title="Description:" ref="commProductDescrRef" />
    <b-form-group
      label-cols-md="1"
      label="Image Key:"
      label-align-md="right"
      label-for="imgKey">
      <b-form-input
        id="imgKey"
        v-model="formData.imgKey"
        @blur="commProductImputHandleBlur($event)"
        @keyup.enter="commProductImputHandleBlur($event)" />
      <img
        id="commProductImage"
        :src="formData.imgSrc"
        ref="commProductImgRef"
        :style="imgStyle" />
        <!-- style= "max-width: 320px; height: auto;" /> -->
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Gift card:"
      label-align-md="right"
      label-for="voucher">
      <b-form-checkbox
        id="voucher"
        v-model="formData.voucher" switch />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Available:"
      label-align-md="right"
      label-for="available">
      <b-form-checkbox
        id="available"
        v-model="formData.available" switch />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Price / Currency / Base price:"
      label-align-md="right"
      label-for="priceCurrBaseprice">
      <b-form-row>
        <b-col md="4">
          <b-form-input
            id="price"
            v-model="formData.price" />
        </b-col>
        <b-col md="4">
          <b-form-input
            id="currency"
            v-model="formData.currency" />
        </b-col>
        <b-col md="4">
          <b-form-input
            id="basePrice"
            v-model="formData.basePrice" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Producer / Internal Link / Delivery:"
      label-align-md="right"
      label-for="priceCurrBaseprice">
      <b-form-row>
        <b-col md="4">
          <b-form-input
            id="price"
            v-model="formData.producer" />
        </b-col>
        <b-col md="4">
          <b-form-input
            id="internalLint"
            v-model="formData.internalLink" />
        </b-col>
        <b-col md="4">
          <b-form-spinbutton
            id="delivery"
            min="1"
            max="10"
            step=".5"
            v-model="formData.delivery" />
        </b-col>
      </b-form-row>
    </b-form-group>
  </div>
</template>

<script>
import config from '@/config'
import { Configuration, OpenAIApi } from 'openai'
import * as layoutSvc from '@/services/utils/layout'

import GlText from '@/components/content/GlText'

const MAX_WIDTH_STYLE = 'max-width: 320px; height: auto;'
const MAX_HEIGHT_STYLE = 'width: auto; max-height: 240px;'

const NO_IMAGE_AVAILABLE = require('@/assets/content/no_img_available-2-320x240.jpg')
const __defaultFormData = {
  structData: '',
  categorySelected: null,
  tags: [],
  listOrder: 0,
  descr: '',
  imgKey: null,
  imgSrc: NO_IMAGE_AVAILABLE,
  voucher: false,
  available: false,
  price: 0.0,
  currency: 'EUR',
  basePrice: '0 EUR / KG',
  producer: 'N.A.',
  internalLink: '',
  delivery: 3
}

const openAIConfig = new Configuration({ apiKey: config.openaiApiKey })

export default {
  name: 'gl-comm-product',
  version: '0.0.2',
  props: {
    commProductCtgyTypeCat: {
      type: Array,
      default: () => []
    }, // commProductCtgyTypeCat
    initialData: {
      type: Object,
      default: () => {}
    }, // initialData
    findContentByKey: {
      type: Function
    } // findContentByKey
  }, // props
  data () {
    return {
      imgStyle: MAX_WIDTH_STYLE,
      modalAIDescr: {
        commProdName: '',
        commProdFF: '',
        stateValid: false
      },
      aiDescrLoading: false,
      // formData: this.initialData || Object.assign({}, __defaultFormData)
      formData: this.initialData ? Object.assign({}, this.initialData) : Object.assign({}, __defaultFormData)
    }
  }, // data
  /* ----- imported components ----- */
  components: {
    GlText
  }, // components
  /* ----- vuejs hooks ----- */
  mounted () {
    if (!this.formData.categorySelected) {
      if (this.commProductCtgyTypeCat && this.commProductCtgyTypeCat.length > 0) {
        this.formData.categorySelected = this.commProductCtgyTypeCat[0].code
      } else {
        console.warn('GlCommProduct: error by the initialiazation!')
      }
    }
    if (this.formData.descr && this.formData.descr !== '') {
      this.$refs.commProductDescrRef.setContent(this.formData.descr)
    }
  }, // mounted
  /* ----- vuejs observers ----- */
  watch: {
    initialData: function (nv) {
      this.formData = Object.assign({}, this.initialData)
      if (this.formData.descr && this.formData.descr !== '') {
        this.$refs.commProductDescrRef.setContent(this.formData.descr)
      }
    } // initialData
  }, // watch
  methods: {
    /* ----- public methods ----- */
    getFormData () {
      this.formData.descr = this.$refs.commProductDescrRef.getJSON()
      return this.formData
    }, // getFormData
    /* ----- handler methods ----- */
    async handleAIDescrModalOk (ev) {
      ev.preventDefault()
      await this.handleAIDescrModalSubmit()
    }, // handleAIDescrModalOk
    async handleAIDescrModalSubmit () {
      if (this.modalAIDescr.commProdName) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-ai-descr')
        })
        try {
          this.aiDescrLoading = true
          const openaiDescr = await this.getOpenaiDescr(this.modalAIDescr.commProdName, this.modalAIDescr.commProdFF)
          this.$refs.commProductDescrRef.setContent(openaiDescr)
        } catch (e) {
          console.error(e)
          console.log('Error is: ', { e })
          this.$bvToast.toast('Currently there is some problem[' + e + '] connecting to the AI! Please try again later!')
        } finally {
          this.aiDescrLoading = false
        }
      }
    }, // handleAIDescrModalSubmit
    commProductImputHandleBlur (ev) {
      const _imageContentFound = this.findContentByKey(ev.target.value)
      if (_imageContentFound) {
        const _imgHeight = _imageContentFound.fragments.imgHeight
        const _imgWidth = _imageContentFound.fragments.imgWidth
        console.log('TRACE-REMOVE: GlCommProduct.HandleBlur: height / width is: ', _imgHeight, _imgWidth)
        if (_imgHeight && _imgWidth && (_imgHeight > 240) &&
          ((_imgHeight / 240) > (_imgWidth / 320))) {
          this.imgStyle = MAX_HEIGHT_STYLE
        } else {
          this.imgStyle = MAX_WIDTH_STYLE
        }
        this.formData.imgSrc = _imageContentFound.fragments.imgUrl
      } else {
        this.formData.imgSrc = NO_IMAGE_AVAILABLE
      }
    }, // commProductImputHandleBlur
    /* ----- util / helper methods ----- */
    /**
    * p is the product names
    * ff are the focus features
    */
    async getOpenaiDescr (p, ff) {
      const openai = new OpenAIApi(openAIConfig)

      let prompt = `Describe product ${p}`
      if (ff) {
        prompt += `, with a focus on the features: ${ff}`
      }
      console.log('TRACE: GlCommProduct.getOpenaiDescr: will ask: ' + prompt)
      const response = await openai.createCompletion({
        model: 'text-davinci-003',
        prompt,
        temperature: 0.7,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
      })
      return response.data.choices[0].text.trim()
    }, // getOpenaiDescr
    mapCatalog2Select: layoutSvc.mapCatalog2Select,
    isValidTag (tag) {
      return tag.length > 2 && tag.length < 21
    } // isValidTag
  } // methods
}
</script>

<style scoped>
</style>
