<template>
  <div>
    <b-form-group
      label-cols-sm="1"
      label="Structured data (json-ld):"
      label-align-sm="right"
      label-for="structData">
      <b-form-textarea
        id="structData"
        v-model="formData.structData"
        placeholder="Enter some meta description ..."
        rows="2"
        max-rows="3" />
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Introduction:"
      label-align-sm="right"
      label-for="intro">
      <b-form-textarea
        id="intro"
        v-model="formData.intro"
        placeholder="Enter some introduction ..."
        rows="2"
        max-rows="3" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Tags / List order:"
      label-align-md="right"
      label-for="tags">
      <b-form-row>
        <b-col md="6">
          <b-form-tags id="tags" v-model="formData.tags" ref="tagsRef" :limit="5"
            :tag-validator="isValidTag" placeholder="Enter up to 20 chars, separated by space / enter"
            separator=" " tag-pills tag-variant="primary" remove-on-delete></b-form-tags>
        </b-col>
        <b-col md="6">
          <b-form-input id="listOrder" v-model="formData.listOrder" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Event type / Venue:"
      label-for="eventType"
      v-slot="{ ariaDescribedby }">
      <b-form-row>
        <b-col md="2">
          <b-form-radio-group
            id="eventType"
            v-model="formData.eventType"
            :options="eventTypeOpts"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"
            size="md"
            name="eventType"
            @change="handleEventTypeChange"
            buttons></b-form-radio-group>
        </b-col>
        <b-col md="3" v-if="formData.eventType == 'presence'">
          <b-form-input
            id="venueStr"
            v-model="formData.venue.str" placeholder="Street and number" />
        </b-col>
        <b-col md="1" v-if="formData.eventType == 'presence'">
          <b-form-input
            id="venueZip"
            v-model="formData.venue.zip" placeholder="ZIP" />
        </b-col>
        <b-col md="3" v-if="formData.eventType == 'presence'">
          <b-form-input
            id="venueCity"
            v-model="formData.venue.city" placeholder="City" />
        </b-col>
        <b-col md="3" v-if="formData.eventType == 'presence'">
          <b-form-input
            id="venueCountry"
            v-model="formData.venue.country" placeholder="Country" />
        </b-col>
        <b-col md="10" v-if="formData.eventType == 'virtual'">
          <b-form-input
            id="venueVirtual"
            v-model="formData.venue.virtual" placeholder="Web link" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Capacity / Price:"
      label-align-md="right"
      label-for="capacityPrice">
      <b-form-row>
        <b-col md="3">
          <b-form-input id="capacity"
            v-model="formData.capacity" placeholder="Capacity (0 for no restrictions)" />
        </b-col>
        <b-col md="3">
          <b-form-input id="price"
            v-model="formData.price" placeholder="Price (0 for free)" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Date / time:"
      label-align-sm="right"
      label-for="dateTime">
      <b-form-row>
        <b-col md="3">
          <b-form-datepicker id="eventDate" v-model="formData.eventDate" class="mb-2" />
        </b-col>
        <b-col md="3">
          <b-form-timepicker id="eventTime" v-model="formData.eventTime" class="mb-2" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Image Key:"
      label-align-md="right"
      label-for="imgKey">
      <b-form-input
        id="imgKey"
        v-model="formData.imgKey"
        @blur="eventImputHandleBlur($event)"
        @keyup.enter="eventImputHandleBlur($event)" />
      <img
        id="eventImage"
        :src="formData.imgSrc"
        ref="eventImageRef"
        width= "320"
        height= "240" />
    </b-form-group>
    <gl-text title="Body:" ref="eventBodyRef" />
  </div>
</template>

<script>
import GlText from '@/components/content/GlText'

const NO_IMAGE_AVAILABLE = require('@/assets/content/no_img_available-2-320x240.jpg')

const EVENT_TYPE_DEFS = {
  PRESENCE: { text: 'Presence', value: 'presence' },
  VIRTUAL: { text: 'Virtual', value: 'virtual' }
}

const __defaultFormData = {
  structData: '',
  intro: '',
  tags: [],
  listOrder: 0,
  eventType: EVENT_TYPE_DEFS.PRESENCE.value,
  venue: {
    str: '',
    zip: '',
    city: '',
    country: ''
  },
  capacity: '',
  price: '',
  eventDate: '', // TODO: make it today!
  eventTime: '20:00',
  imgKey: null,
  imgSrc: NO_IMAGE_AVAILABLE,
  body: ''
}

export default {
  name: 'gl-event',
  version: '0.0.3',
  props: {
    initialData: {
      type: Object,
      default: () => {}
    }, // initialData
    findContentByKey: {
      type: Function
    } // findContentByKey
  }, // props
  data () {
    return {
      eventTypeOpts: [
        EVENT_TYPE_DEFS.PRESENCE,
        EVENT_TYPE_DEFS.VIRTUAL
      ],
      formData: this.initialData ? Object.assign({}, this.initialData) : Object.assign({}, __defaultFormData)
    }
  }, // data
  /* ----- imported components ----- */
  components: {
    GlText
  }, // components
  /* ----- vuejs hooks ----- */
  mounted () {
    if (this.formData.body && this.formData.body !== '') {
      this.$refs.eventBodyRef.setContent(this.formData.body)
    }
  }, // mounted
  /* ----- vuejs observers ----- */
  watch: {
    initialData: function (nv) {
      this.formData = Object.assign({}, this.initialData)
      if (this.formData.body && this.formData.body !== '') {
        this.$refs.eventBodyRef.setContent(this.formData.body)
      }
    } // initialData
  }, // watch
  methods: {
    /* ----- public methods ----- */
    getFormData () {
      console.log('TRACE-REMOVE: formData.eventType is: ', this.formData.eventType)
      console.log('TRACE-REMOVE: initialData.eventType is: ', this.initialData?.eventType)
      if (this.initialData) {
        if (this.formData.eventType !== this.initialData.eventType) {
          if (this.formData.eventType === EVENT_TYPE_DEFS.VIRTUAL.value) {
            this.formData.venue.str = ''
            this.formData.venue.zip = ''
            this.formData.venue.city = ''
            this.formData.venue.country = ''
          } else if (this.formData.eventType === EVENT_TYPE_DEFS.PRESENCE.value) {
            this.formData.venue.virtual = ''
          }
        }
      }
      this.formData.body = this.$refs.eventBodyRef.getJSON()
      return this.formData
    }, // getFormData
    /* ----- handler methods ----- */
    handleEventTypeChange (ev) {
      console.log('TRACE-REMOVE: ev is: ', ev)
    }, // handleEventTypeChange
    eventImputHandleBlur (ev) {
      if (this.findContentByKey) {
        const _imageContentFound = this.findContentByKey(ev.target.value)
        if (_imageContentFound) {
          this.formData.imgSrc = _imageContentFound.fragments.imgUrl
        } else {
          this.formData.imgSrc = NO_IMAGE_AVAILABLE
        }
      } else {
        this.formData.imgSrc = NO_IMAGE_AVAILABLE
      }
    }, // eventImputHandleBlur
    isValidTag (tag) {
      return tag.length > 2 && tag.length < 21
    } // isValidTag
  } // methods
}
</script>

<style scoped>
</style>
