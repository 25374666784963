<template>
  <!-- <b-container>
    <canvas id="pdfDocCanvas"></canvas>
  </b-container> -->
  <div>
    <canvas id="pdfDocCanvas"></canvas>
  </div>
</template>

<script>

export default {
  name: 'pdf-viewer',
  version: '0.0.1',
  props: {
    fileUrl: {
      type: String,
      default: ''
    }, // fileUrl
    signAreaList: {
      type: Array,
      default: () => []
    } // signAreaList
  }, // props
  data () {
    return {
      pdfLib: null,
      pdfDoc: null,
      pdfPage: null,
      canvasCtx: null
    }
  }, // data
  watch: {
    fileUrl: {
      handler: async function (nv, ov) {
        console.log('TRACE-REMOVE: fileUrl.watch.handler: nv is: ', nv)
        await this.renderPdfDoc()
      },
      deep: true
    },
    signAreaList: {
      handler: async function (nv, ov) {
        console.log('TRACE-REMOVE: signAreaList.watch.handler: nv is: ', nv)
        await this.renderPdfDoc()
      },
      deep: true
    } // signAreaList
  }, // watch
  async mounted () {
    try {
      this.pdfLib = await import('pdfjs-dist/legacy/build/pdf.min')
      console.log('TRACE-REMOVE: this.pdfLib is: ', this.pdfLib)
      this.pdfLib.GlobalWorkerOptions.workerSrc =
        'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.10.377/build/pdf.worker.min.js'
      this.pdfDoc = await this.pdfLib.getDocument(this.fileUrl).promise
      console.log('TRACE-REMOVE: this.pdfDoc is: ', this.pdfDoc)
      this.pdfPage = await this.pdfDoc.getPage(1)
      const viewport = this.pdfPage.getViewport({ scale: 1.0 })
      const canvas = document.getElementById('pdfDocCanvas')
      canvas.width = viewport.width
      canvas.height = viewport.height
      this.canvasCtx = canvas.getContext('2d')
      this.renderPdfDoc()
    } catch (e) {
      console.error('ERROR: Error loading the document: ', e)
    }
  }, // mounted
  methods: {
    async renderPdfDoc () {
      const viewport = this.pdfPage.getViewport({ scale: 1.0 })
      await this.pdfPage.render({
        canvasContext: this.canvasCtx,
        viewport
      }).promise
      this.canvasCtx.strokeStyle = 'red'
      for (var sa of this.signAreaList) {
        this.canvasCtx.strokeRect(sa.x, sa.y, 250, 80)
      }
    } // renderPdfDoc
  }
}
</script>

<style>
#pageContainer {
  margin: auto;
  width: 80%;
}

div.page {
  display: inline-block;
}
</style>
