<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      title="available instances"
      style="background-color: lightblue; margin: 2px;">
      <b-table
        :fields="fieldsDefs"
        :items="instances"
        :busy="loading"
        >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <a @click="handleNotImplemented()" class="gl-ttip">
            <b-icon icon="graph-up" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">statistics</span>
          </a>
          <a :href="$router.localizedRoute('/dashboard/inst/' + data.item._id +
            '/settings/' + getDomainForInstTypeCat(data.item.instTypeCode))"
            class="gl-ttip">
            <b-icon icon="tools" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">settings</span>
          </a>
          <a :href="$router.localizedRoute('/dashboard/inst/' + data.item._id +
            '/manage/' + getDomainForInstTypeCat(data.item.instTypeCode))"
            class="gl-ttip">
            <b-icon icon="card-checklist" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">manage</span>
          </a>
          <a @click="handleNotImplemented()" class="gl-ttip">
            <b-icon icon="trash" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">delete</span>
          </a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { createNamespacedHelpers } from 'vuex'
import {
  states as accntStNames,
  actions as accntActNames,
  moduleNamespace as accntModuleNSp,
  getNamespacedName } from '@/store/modules/account/names'
import {
  states as glapiStNames,
  actions as glapiActionsNames,
  moduleNamespace as glapiModuleNSp } from '@/store/modules/glapi/names'

const { mapState } = createNamespacedHelpers(accntModuleNSp)
const { mapState: mapGlapiState } = createNamespacedHelpers(glapiModuleNSp)

export default {
  data () {
    return {
      fieldsDefs: [
        {
          key: 'instTypeCode',
          label: 'Instance type'
        },
        {
          key: 'instKey',
          label: 'Instance key'

        },
        {
          key: 'subscrId',
          label: 'Subscription'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      loading: true
    }
  }, // data
  async mounted () {
    try {
      await this.$store.dispatch(getNamespacedName(accntActNames.FETCH_INSTANCES))
      await this.$store.dispatch(glapiModuleNSp + '/' + glapiActionsNames.FETCH_INST_TYPE_CAT)
      console.log('TRACE-REMOVE: Dashboard.mounted: this.instances is: ', this.instances)
      console.log('TRACE-REMOVE: Dashboard.mounted: this.instTypeCat is: ', this.instTypeCat)
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState([[accntStNames.INSTANCES]]),
    ...mapGlapiState([[glapiStNames.INST_TYPE_CAT]])
  }, // computed
  methods: {
    handleNotImplemented () {
      this.$bvToast.toast('This option is still to be implemented!')
    }, // handleNotImplemented
    getDomainForInstTypeCat (instCode) {
      console.log('TRACE-REMOVE: Dashboard.getDomainForInstTypeCat: instCode is: ', instCode)
      console.log('TRACE-REMOVE: Dashboard.getDomainForInstTypeCat: this.instTypeCat is: ', this.instTypeCat)
      const _instFound = Object.values(this.instTypeCat).find(v => v.code === instCode)
      console.log('TRACE-REMOVE: Dashboard.getDomainForInstTypeCat: _instFound is: ', _instFound)
      var _ret = 'default'
      if (_instFound) {
        _ret = _instFound.domain
      }

      return _ret
    } // getDomainForInstTypeCat
  }
}
</script>

<style scoped>
a {
  padding-left: 15px;
}
.cmpn {
  margin-top: 85px;
}
</style>
<style lang="scss">
@import "@/assets/style/scss/main.scss";
</style>
