<template>
  <b-form-group label-cols-sm="1" label="Document:" label-align-sm="right">
    <b-form-group v-if="formData.pdfSrc" label-cols-sm="1" label="Sign areas:"
      label-for="id-aignAreas-field" label-align-sm="right" v-slot="{ ariaDescribedby }">
      <b-input-group style="max-width: 600px;">
        <b-form-input
          id="id-aignAreas-field"
          v-model="signAreaEntered"
          placeholder="Enter sign area"
          @keyup.enter="handleAddSignArea()" />
        <b-input-group-append>
          <b-button
            variant="outline-info"
            @click="handleAddSignArea()">Add
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-list-group class="py-1" style="max-width: 595px;">
        <b-list-group-item
          v-for="(sa, ndx) in formData.signAreaList"
          :key="ndx"
          class="d-flex justify-content-between align-items-center">
          <b-form-input class="w-25" type="number" min="0" max="1000"
            v-model="sa.x" />
          <b-form-input class="w-25" type="number" min="0" max="1000"
            v-model="formData.signAreaList[ndx].y" />
          <b-button
            variant="outline-info"
            @click="handleRemoveSignArea(ndx)">Remove
          </b-button>
        </b-list-group-item>
      </b-list-group>
      <pdf-viewer v-if="formData.pdfSrc" :fileUrl="formData.pdfSrc" :signAreaList="formData.signAreaList" />
    </b-form-group>
    <img
      v-else
      id="loadPdfDocImage"
      :src="imageSrc"
      ref="contentImageRef"
      @click="$refs.file.click()" />
    <input ref="file" type="file" hidden @change="handleLoadFile($event)"/>
  </b-form-group>
</template>

<script>
import PdfViewer from '@/components/3rdParty/mozilla/PdfViewer'

const NO_IMAGE_AVAILABLE = require('@/assets/content/pdf-upload-256px.png')

const __getDefaultFormData = () => { return { pdfSrc: '', signAreaList: [] } }

export default {
  name: 'gl-image',
  version: '0.0.1',
  props: {
    initialData: {
      type: Object,
      default: () => null
    } // initialData
  }, // props
  data () {
    return {
      imageSrc: NO_IMAGE_AVAILABLE,
      signAreaEntered: '',
      formData: this.initialData ? Object.assign({}, this.initialData) : __getDefaultFormData()
    }
  }, // data
  watch: {
    initialData: {
      handler: function (nv, ov) {
        console.log('TRACE-REMOVE: GlPdfDoc.initialData.watch.handler: nv, ov is: ', nv, ov)
        // await this.renderPdfDoc()
        this.formData = Object.assign({}, nv)
      },
      deep: true
    } // initialData
  }, // watch
  mounted () {
    console.log('TRACE-REMOVE: GlPdfDoc.mounted: this.initialData is: ', this.initialData)
  }, // mounted
  methods: {
    /* ----- public methods ----- */
    getFile () {
      return this.$refs.file.files[0]
    }, // getFile
    getSignAreaList () {
      return this.formData.signAreaList
    }, // getSignAreaList
    /* ----- private methods ----- */
    handleLoadFile (e) {
      var reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      // var _file = e.target.files[0]
      reader.onload = (e) => {
        // this.imageSrc = e.target.result
        this.formData.pdfSrc = e.target.result
      }
    }, // handleLoadFile
    handleAddSignArea () {
      if (this.signAreaEntered && (this.signAreaEntered !== '')) {
        const [x, y] = this.signAreaEntered.split('&')
        this.formData.signAreaList.push({ x, y })
        this.signAreaEntered = ''
      }
    }, // handleAddSignArea
    handleRemoveSignArea (index) {
      this.formData.signAreaList.splice(index, 1)
    } // handleRemoveSignArea
  }, // methods
  components: {
    // GlCropper,
    PdfViewer
  }
}
</script>

<style lang="scss" scoped>
</style>
