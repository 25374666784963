<template>
  <div>
    <b-form-group
      label-cols-md="1"
      label="Tags:"
      label-align-md="right"
      label-for="tags">
      <b-form-tags id="tags" v-model="formData.tags" ref="tagsRef" :limit="5"
        :tag-validator="isValidTag" placeholder="Enter up to 20 chars, separated by space / enter"
        separator=" " tag-pills tag-variant="primary" remove-on-delete></b-form-tags>
    </b-form-group>
    <gl-text title="Desciption:" ref="commProductDescrRef" />
    <b-form-group
      label-cols-md="1"
      label="Remote:"
      label-align-md="right"
      label-for="remote">
      <b-form-checkbox
        id="remote"
        v-model="formData.remote" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Street / Zip:"
      label-align-md="right"
      label-for="streetZip">
      <b-form-row>
        <b-col md="8">
          <b-form-input
            id="street"
            v-model="formData.street"
            :disabled="formData.remote" />
        </b-col>
        <b-col md="4">
          <b-form-input
            id="zip"
            v-model="formData.zip"
            :disabled="formData.remote" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="City / Country:"
      label-align-md="right"
      label-for="cityCountry">
      <b-form-row>
        <b-col md="6">
          <b-form-input
            id="city"
            v-model="formData.city"
            :disabled="formData.remote" />
        </b-col>
        <b-col md="6">
          <b-form-input
            id="country"
            v-model="formData.country"
            :disabled="formData.remote" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Valid until:"
      label-align-sm="right"
      label-for="validUntil">
      <b-form-row>
        <b-col md="4">
          <b-form-datepicker id="eventDate" v-model="formData.validUntil" class="mb-2" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Employment type:"
      label-for="emplmntType"
      v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="emplmntType"
        v-model="formData.emplmntType"
        :options="emplmntTypeOpts"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="md"
        name="employmentType"
        buttons></b-form-radio-group>
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Salary:"
      label-for="showSalary"
      v-slot="{ ariaDescribedby }">
      <b-form-row>
        <b-col md="2">
          <b-form-checkbox
            id="showSalary"
            v-model="formData.salaryShow"
            name="showSalary">
            Show salary
          </b-form-checkbox>
        </b-col>
        <b-col md="4">
          <b-form-input
            id="city"
            v-model="formData.salary"
            :disabled="!formData.salaryShow" />
        </b-col>
        <b-col md="6">
          <b-form-radio-group
            id="salaryUnit"
            v-model="formData.salaryUnit"
            :options="salaryUnitOptions"
            :aria-describedby="ariaDescribedby"
            :disabled="!formData.salaryShow"
            button-variant="outline-primary"
            size="md"
            name="salaryUnit"
            buttons></b-form-radio-group>
        </b-col>
      </b-form-row>
    </b-form-group>
  </div>
</template>

<script>
import GlText from '@/components/content/GlText'

/** Employment type options definitions */
const ETO_DEFS = {
  FULL_TIME: { text: 'Full time', value: 'fullTime' },
  PART_TIME: { text: 'Part time', value: 'partTime' },
  CONTRACTOR: { text: 'Contractor', value: 'contractor' },
  TEMPORARY: { text: 'Temporary', value: 'temporary' },
  SEASONAL: { text: 'Seasonal', value: 'seasonal' },
  INTERNSHIP: { text: 'Internship', value: 'internship' }
}

const SALARY_UNIT_DEFS = {
  HOUR: { text: 'Hour', value: 'hour' },
  DAY: { text: 'Day', value: 'day' },
  MONTH: { text: 'Month', value: 'month' },
  YEAR: { text: 'Year', value: 'year' }
}

const DEFAULT_FORM_DATA = {
  tags: [],
  descr: '',
  remote: false,
  street: '',
  city: '',
  zip: '',
  country: '',
  validUntil: null,
  emplmntType: ETO_DEFS.FULL_TIME.value,
  salaryShow: true,
  salary: 0,
  salaryUnit: SALARY_UNIT_DEFS.HOUR.value
}

export default {
  name: 'gl-comm-ad',
  version: '0.0.1',
  props: {
    // commProductCtgyTypeCat: {
    //   type: Array,
    //   default: () => []
    // }, // commProductCtgyTypeCat
    initialData: {
      type: Object,
      default: () => {}
    }, // initialData
    findContentByKey: {
      type: Function
    } // findContentByKey
  }, // props
  data () {
    return {
      emplmntTypeOpts: [
        ETO_DEFS.FULL_TIME,
        ETO_DEFS.PART_TIME,
        ETO_DEFS.CONTRACTOR,
        ETO_DEFS.TEMPORARY,
        ETO_DEFS.SEASONAL,
        ETO_DEFS.INTERNSHIP
      ],
      salaryUnitOptions: [
        SALARY_UNIT_DEFS.HOUR,
        SALARY_UNIT_DEFS.DAY,
        SALARY_UNIT_DEFS.MONTH,
        SALARY_UNIT_DEFS.YEAR
      ],
      formData: this.initialData ? Object.assign({}, this.initialData) : Object.assign({}, DEFAULT_FORM_DATA)
    }
  }, // data
  /* ----- imported components ----- */
  components: {
    GlText
  }, // components
  /* ----- vuejs hooks ----- */
  mounted () {
    // if (!this.formData.categorySelected) {
    //   if (this.commProductCtgyTypeCat && this.commProductCtgyTypeCat.length > 0) {
    //     this.formData.categorySelected = this.commProductCtgyTypeCat[0].code
    //   } else {
    //     console.warn('GlCommProduct: error by the initialiazation!')
    //   }
    // }
    if (this.formData.descr && this.formData.descr !== '') {
      this.$refs.commProductDescrRef.setContent(this.formData.descr)
    }
  }, // mounted
  /* ----- vuejs observers ----- */
  watch: {
    initialData: function (nv) {
      this.formData = Object.assign({}, this.initialData)
      if (this.formData.descr && this.formData.descr !== '') {
        this.$refs.commProductDescrRef.setContent(this.formData.descr)
      }
    } // initialData
  }, // watch
  methods: {
    /* ----- public methods ----- */
    getFormData () {
      this.formData.descr = this.$refs.commProductDescrRef.getJSON()
      return this.formData
    }, // getFormData
    /* ----- handler methods ----- */
    /* ----- util methods ----- */
    isValidTag (tag) {
      return tag.length > 2 && tag.length < 21
    } // isValidTag
  } // methods
}
</script>

<style scoped>
</style>
