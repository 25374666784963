import { render, staticRenderFns } from "./GlEvent.vue?vue&type=template&id=541ed4ed&scoped=true&"
import script from "./GlEvent.vue?vue&type=script&lang=js&"
export * from "./GlEvent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541ed4ed",
  null
  
)

export default component.exports