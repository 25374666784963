import { render, staticRenderFns } from "./GlBlogPost.vue?vue&type=template&id=15b19981&scoped=true&"
import script from "./GlBlogPost.vue?vue&type=script&lang=js&"
export * from "./GlBlogPost.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b19981",
  null
  
)

export default component.exports