<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'manage instance: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      header-class="text-center"
      title="Appointments calendar:"
      style="background-color: lightblue; margin: 2px;">
      <i class="bi-calendar"></i>
      <glcwc-schedule-timeslot-calendar :inst-key="instKey" mode="admin" />
      <glcwc-schedule-timeslot-calendar :inst-key="instKey" />
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import '@/plugins/bootstrap-vue-dash'

import {
  states as accountStNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'manage-inst-schedule',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      orderLoaded: false,
      // TODO: all form data in one formData object
      instKey: '',
      formData: {
        glapiId: ''
      }
    }
  }, // data
  async created () {
    try {
      this.loading = true
      console.log('TRACE-REMOVE: ManageinstSchedule.created: this.instances is: ', this.instances)
      // await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_CONTENT))
      const _foundInst = this.instances.find(e => e._id === this.$route.params.instId)
      if (_foundInst) {
        this.instKey = _foundInst.instKey
      } else {
        // TODO: consider route to the dashboard or error page
        // console.log('WARN-REMOVE: no key found for the instId: ', this.$route.params.instId)
      }
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      // console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(accountModuleNSp, [[accountStNames.INSTANCES]])
  }, // computed
  methods: {
  }, // methods
  components: {
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
</style>
