<template>
  <div ref="glCropperRef" />
</template>

<script>
import Croppie from 'croppie'

export default {
  name: 'gl-cropper',
  version: '0.0.2',
  props: {
    boundary: {
      type: Object,
      default: () => { return { width: 320, height: 240 } }
    },
    customClass: String,
    enableExif: Boolean,
    enableOrientation: {
      type: Boolean,
      default: true
    },
    enableResize: {
      type: Boolean,
      default: true
    },
    enableZoom: {
      type: Boolean,
      default: true
    },
    enforceBoundary: {
      type: Boolean,
      default: true
    },
    mouseWheelZoom: {
      type: [Boolean, String],
      default: true
    },
    showZoomer: {
      type: Boolean,
      default: true
    },
    viewport: {
      type: Object,
      default: function () {
        return {
          width: 320,
          height: 240,
          type: 'square'
        }
      }
    },
    minZoom: Number,
    maxZoom: Number
  }, // props
  data () {
    return {
      croppie: null
    }
  }, // datass
  /* ----- vuejs hooks ----- */
  mounted () {
    this.init()
  }, // mounted
  methods: {
    /* ----- private methods ----- */
    init () {
      const _el = this.$refs.glCropperRef
      const _options = Object.assign({}, this.$props)
      _el.addEventListener('update', (ev) => {
        this.$emit('update', ev.detail)
      })

      this.croppie = new Croppie(_el, _options)
    }, // init
    bind (options) {
      return this.croppie.bind(options)
    }, // bind
    destroy () {
      this.croppie.destroy()
    }, // destroy
    result (options, cb) {
      if (!options) options = { type: 'base64' }
      return this.croppie.result(options).then(output => {
        if (!cb) {
          this.$emit('result', output)
        } else {
          cb(output)
        }
        return output
      })
    } // result
  } // methods
}
</script>

<style lang="css">
@import '~croppie/croppie.css';
.croppie-container .cr-boundary {
  margin: 0px;
}
.croppie-container .cr-slider-wrap {
  margin: 0px;
  text-align: left;
  width: 100%;
}
</style>
