<template>
  <div>
    <b-form-group label-cols-sm="1" label="EMail Field:" label-for="id-email-field"
      label-align-sm="right" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="id-email-field"
        v-model="formData.emailField"
        :options="radioOptions"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="md"
        name="email-field"
        buttons></b-form-radio-group>
    </b-form-group>
    <b-form-group label-cols-sm="1" label="Name Field:" label-for="id-name-field"
      label-align-sm="right" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="id-name-field"
        v-model="formData.nameField"
        :options="radioOptions"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="md"
        name="name-field"
        buttons></b-form-radio-group>
    </b-form-group>
    <b-form-group label-cols-sm="1" label="amount options:" label-align-sm="right"
      label-for="inputAmount">
      <b-input-group>
        <b-form-input
          id="inputAmount"
          v-model="amountEntered"
          type="number"
          min="0"
          max="1000"
          placeholder="Enter amount option"
          @keyup.enter="handleAddAmount()" />
        <b-input-group-append>
          <b-button
            variant="outline-info"
            @click="handleAddAmount()">Add
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-list-group>
        <b-list-group-item
          v-for="(occ, ndx) in formData.amountList"
          :key="ndx"
          class="d-flex justify-content-between align-items-center">
          {{ occ }}
          <b-button
            variant="outline-info"
            @click="handleRemoveAmount(ndx)">Remove
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-form-group>
  </div>
</template>

<script>
const __defaultFormData = {
  emailField: 'no',
  nameField: 'no',
  amountList: []
}

export default {
  name: 'gl-donation',
  version: '0.0.1',
  props: {
    initialData: {
      type: Object,
      default: () => {}
    } // initialData
  }, // props
  components: {}, // components
  data () {
    return {
      loaded: false,
      radioOptions: [
        { text: 'No', value: 'no' },
        { text: 'Optional', value: 'optional' },
        { text: 'Mandatory', value: 'mandatory' }
      ],
      amountEntered: '',
      formData: this.initialData ? Object.assign({}, this.initialData) : Object.assign({}, __defaultFormData)
    }
  }, // data
  /* ----- vuejs hooks ----- */
  mounted () {}, // mounted
  computed: {}, // computed
  watch: {}, // watch
  methods: {
    /* ----- public methods ----- */
    getFormData () {
      // this.formData.body = this.$refs.eventBodyRef.getJSON()
      return this.formData
    }, // getFormData
    setContent (c) {
      this.formData = c
    }, // setContent
    /* ----- handler methods ----- */
    handleAddAmount () {
      console.log('TRACE-REMOVE: GlDonation.handleAddOccation: this.amountEntered is: ', this.amountEntered)
      if (this.amountEntered && (this.amountEntered !== '')) {
        if (this.amountEntered < 1 || this.amountEntered > 1000) {
          this.$bvToast.toast('Only values between 1 and 1000 are alowed!')
        } else if (this.formData.amountList.length >= 5) {
          this.$bvToast.toast('Maximum 5 amounts are alowed!')
        } else {
          this.formData.amountList.push(parseFloat(this.amountEntered).toFixed(2))
          this.amountEntered = ''
        }
      }
    }, // handleAddAmount
    handleRemoveAmount (index) {
      this.formData.amountList.splice(index, 1)
    } // handleRemoveAmount
  } // methods
}
</script>

<style lang="scss" scoped>
</style>
