<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'instance key: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      header-class="text-center"
      title="instance settings:"
      style="background-color: lightblue; margin: 2px;">
      <b-form-group
        class="mb-0"
        label="Publish domain URL List:"
        label-for="publishUrl1"
        description="First url allowed to publish your content">
        <b-form-input
          id="publishUrl1"
          v-model="formData.publishUrl1"
          placeholder="https://mydomain-nr1.org" />
      </b-form-group>
      <b-form-group
        class="mb-0"
        label=" "
        label-for="publishUrl2"
        description="Second url allowed to publish your content">
        <b-form-input
          id="publishUrl2"
          v-model="formData.publishUrl2"
          placeholder="https://mydomain-nr2.org" />
      </b-form-group>
      <b-form-group
        class="mb-0"
        label=" "
        label-for="publishUrl3"
        description="Third url allowed to publish your content">
        <b-form-input
          id="publishUrl3"
          v-model="formData.publishUrl3"
          placeholder="https://mydomain-nr3.org" />
      </b-form-group>
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleUpload()" :disabled="!formDirty">Upload</b-button>
          <b-button @click="handleClear()" :disabled="!formDirty">Clear</b-button>
        </div>
      </template>
      <b-overlay :show="loading" no-wrap></b-overlay>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { mapState, mapActions } from 'vuex'

import {
  states as accountStNames,
  actions as accountActionNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'content-settings',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      instKey: '',
      instId: '',
      formData: {
        publishUrl1: '',
        publishUrl2: '',
        publishUrl3: ''
      }
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      this.instId = this.$route.params.instId
      await this.accntDispatchFetchInst()
      this.initForm()
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(accountModuleNSp, [[accountStNames.INSTANCES]]),
    selectedInst: function () {
      return this.instances.find(e => e._id === this.instId)
    }, // selectedInst
    formDirty: function () {
      const _formData = this.formData.publishUrl1 +
        this.formData.publishUrl2 + this.formData.publishUrl3
      const _storeData = this.selectedInst && this.selectedInst.settings &&
        this.selectedInst.settings.publischUrlList
        ? this.selectedInst.settings.publischUrlList[0] ? this.selectedInst.settings.publischUrlList[0] : '' +
          this.selectedInst.settings.publischUrlList[1] ? this.selectedInst.settings.publischUrlList[1] : '' +
          this.selectedInst.settings.publischUrlList[2] ? this.selectedInst.settings.publischUrlList[2] : ''
        : ''

      return _formData !== _storeData
    }
  }, // computed
  methods: {
    /* ----- mapped action methods ----- */
    ...mapActions(accountModuleNSp,
      { accntDispatchSaveInst: accountActionNames.SAVE_INSTANCE,
        accntDispatchFetchInst: accountActionNames.FETCH_INSTANCES }),
    /* ----- utility methods ----- */
    initForm () {
      const _foundInst = this.selectedInst
      if (_foundInst.settings && _foundInst.settings.publischUrlList) {
        // this.formData.settings = Object.assign({}, this.selectedInst.settings)
        this.instKey = _foundInst.instKey
        this.formData.publishUrl1 = _foundInst.settings.publischUrlList[0]
        this.formData.publishUrl2 = _foundInst.settings.publischUrlList[1]
        this.formData.publishUrl3 = _foundInst.settings.publischUrlList[2]
      }
    }, // initForm
    /* ----- handler methods ----- */
    async handleUpload () {
      try {
        this.loading = true
        await this.accntDispatchSaveInst(
          { instKey: this.selectedInst.instKey,
            settings: {
              publischUrlList: [
                this.formData.publishUrl1,
                this.formData.publishUrl2,
                this.formData.publishUrl3 ] } })
        // this.handleClear()
        this.$bvToast.toast('Upload success!')
      } catch (e) {
        this.$bvToast.toast('Error uploading Content settings!')
        console.log('ERROR-REMOVE: ContentSettings.handleUpload: error is: ', e)
      } finally {
        this.loading = false
      }
    }, // handleUpload
    handleClear () {
      this.initForm()
    } // handleClear
    /* ----- utility methods ----- */
  }, // methods
  components: {
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
</style>
