<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'instance key: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-overlay :show="loading">
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-danger"
        content-class="mt-3"
        @activate-tab="handleTabsChange">
        <b-tab title="General" active>
          <b-card
            header-class="text-center"
            title="Stripe settings:"
            style="background-color: lightblue; margin: 2px; max-width: 350px;">
            <label for="maxPaymAmountId">Maximal payment amount:</label>
            <b-form-input id="maxPaymAmountId" v-model="formData.maxPaymAmount"
              :state="null"
              style="margin-bottom: 15px;"
              placeholder="The amount" trim></b-form-input>
            <b-form-checkbox v-model="formData.paymDisabled" name="paymDisabled" switch>
              Payments disabled
            </b-form-checkbox>
            <div>
              <b-button @click="handleGeneralUpload" style="width: 100%;">Upload</b-button>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Stripe">
          <b-card
            header-class="text-center"
            title="Stripe settings:"
            style="background-color: lightblue; margin: 2px;">
            <b-row>
              <p :style="onboardingStatusStyle">
                {{ onboardingStatusText }}
              </p>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <b-button :disabled="!formData.servAgr" @click="handleStartOnboarding">Start stripe onboarding</b-button>
                </b-row>
                <b-row><b-checkbox v-model="formData.servAgr">Accept <b-link href="/servAgr">service agreement</b-link></b-checkbox></b-row>
              </b-col>
              <b-col>
                <b-button :disabled="true" @click="handleStartOnboarding">Continue stripe onboarding</b-button>
              </b-col>
              <b-col>
                <b-button
                  :disabled="1 !== onboardingStatus"
                  @click="handleFinalizeOnboarding">Finalize stripe onboarding</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-card
                header-class="text-center"
                title="amount to transfer(000.00):"
                style="background-color: lightblue; margin: 2px;">
                <b-form-input id="payAmountId" v-model="formData.payAmount" :state="null"
                  style="margin-bottom: 15px; max-width: 750px;"
                  placeholder="Enter the amount" trim></b-form-input>
                <div id="stripe-card-element"></div>
                <div id="stripe-card-errors" role="alert" style="color: red;"></div>
                <div>
                  <b-button @click="handleStripePay" style="width: 100%;">Pay</b-button>
                </div>
              </b-card>
            </b-row>
            <!-- <b-overlay :show="loading" no-wrap></b-overlay> -->
          </b-card>
        </b-tab>
        <b-tab :disabled="$config.isProd" title="Paypal">
          <b-card
            header-class="text-center"
            title="Paypal settings:"
            style="background-color: lightblue; margin: 2px;">
            <b-button @click="handleStartOnboarding">Start paypal onboarding</b-button>
            <!-- <b-overlay :show="loading" no-wrap></b-overlay> -->
          </b-card>
          <b-card
            header-class="text-center"
            title="amount to transfer(000.00):"
            style="background-color: lightblue; margin: 2px;">
            <b-form-input id="payAmountId" v-model="formData.payAmount" :state="null"
              style="margin-bottom: 15px; max-width: 750px;"
              placeholder="Enter the amount" trim></b-form-input>
            <div ref="paypal"></div>
            <!-- <b-overlay :show="loading" no-wrap></b-overlay> -->
          </b-card>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
// import Vue from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import '@/plugins/bootstrap-vue-dash'
import { mapState, mapActions } from 'vuex'

import {
  states as accountStNames,
  actions as accountActionNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

import {
  actions as glapiActionsNames,
  moduleNamespace as glapiModuleNSp } from '@/store/modules/glapi/names'

const PIT_DEFS = { pitStripe: 'pit-stripe', pitPaypal: 'pit-paypal' }
const ONBOARDING_STATUS = { notStarted: 0, started: 1, completed: 2 }
const ONBOARDING_STATUS_TEXT = ['Integration not available!',
  'Integration started!',
  'Integration completed!']
const ONBOARDING_STATUS_COLOR = ['red', 'blue', 'green']

export default {
  name: 'content-settings',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      instKey: '',
      instId: '',
      pitTabs: ['general', PIT_DEFS.pitStripe, PIT_DEFS.pitPaypal],
      stripe: {
        inst: null,
        elements: null,
        card: null
      },
      formData: {
        payAmount: 10.00,
        selectedPitTab: 0,
        servAgr: false
      }
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      this.instId = this.$route.params.instId
      await this.accntDispatchFetchInst()
      this.loadPaypalSdk()
      console.log('TRACE-REMOVE: PaymentsSettings.mounted: window.stripe is: ',
        window.stripe)
      console.log('TRACE-REMOVE: PaymentsSettings.mounted: this.selectedInst is: ',
        this.selectedInst)
      if (this.selectedInst) {
        this.instKey = this.selectedInst.instKey
        this.formData.maxPaymAmount = this.selectedInst.settings.maxPaymAmount
        this.formData.paymDisabled = this.selectedInst.settings.paymDisabled
        if (!this.stripe.inst &&
          ONBOARDING_STATUS.completed <= this.getStripeOnboardingStatus()) {
          const _stripeConnectedAccountId = this.getStripeConnectedAccountId()
          if (_stripeConnectedAccountId) {
            console.log('TRACE-REMOVE: PaymentsSettings.mounted: will load stripe!')
            this.stripe.inst = await loadStripe(this.$config.stripePlatformPK,
              { stripeAccount: _stripeConnectedAccountId })
            this.stripe.elements = this.stripe.inst.elements()
            this.stripe.card = this.stripe.elements.create('card')
            this.stripe.card.mount('#stripe-card-element')
            this.stripe.card.on('change', ({ error }) => {
              let displayError = document.getElementById('stripe-card-errors')
              if (error) {
                displayError.textContent = error.message
              } else {
                displayError.textContent = ''
              }
            })
          } else {
            console.log('WARNING: PaymentsSettings.mounted: stripeConnectedAccountId not available!')
          }
        }
      }
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(accountModuleNSp, [[accountStNames.INSTANCES]]),
    selectedInst: function () {
      return this.instances.find(e => e._id === this.instId)
    }, // selectedInst
    selectedPit () {
      console.log('TRACE-REMOVE: selectedPit: this.pitTabs is: ', this.pitTabs)
      return this.pitTabs[this.formData.selectedPitTab]
    }, // selectedPit
    onboardingStatus () { // TODO: implement depending on the selected integration
      return this.getStripeOnboardingStatus()
    }, // onboardingStatusText
    onboardingStatusText () { // TODO: implement depending on the selected integration
      return ONBOARDING_STATUS_TEXT[this.getStripeOnboardingStatus()]
    }, // onboardingStatusText
    onboardingStatusStyle () {
      return { color: ONBOARDING_STATUS_COLOR[this.getStripeOnboardingStatus()] }
    } // onboardingStatusStyle
  }, // computed
  methods: {
    /* ----- mapped action methods ----- */
    ...mapActions(accountModuleNSp,
      { accntDispatchSaveInst: accountActionNames.SAVE_INSTANCE,
        accntDispatchFetchInst: accountActionNames.FETCH_INSTANCES }),
    ...mapActions(glapiModuleNSp,
      { glapiCreateOnboarding: glapiActionsNames.CREATE_ONBOARDING,
        glapiCompleteOnboarding: glapiActionsNames.UPDATE_ONBOARDING_COMPLETE,
        glapiCreatePaymentOrder: glapiActionsNames.CREATE_PAYMENT_ORDER,
        glapiUpdatePaymentOrder: glapiActionsNames.UPDATE_PAYMENT_ORDER }),
    /* ----- utility methods ----- */
    loadPaypalSdk () {
      let merchantSrc = ''
      const merchantIntegrations = this.selectedInst.settings.integrations
      if (merchantIntegrations && merchantIntegrations.['pit-paypal']) {
        merchantSrc = '&merchant-id=' +
          merchantIntegrations.['pit-paypal'].merchantDetails.merchantIdInPayPal
      }
      const script = document.createElement('script')
      script.src = this.$config.paypalBaseUrl + 'client-id=' +
        this.$config.paypalPartnerClientId + merchantSrc + '&currency=EUR'
      console.log('TRACE-REMOVE: PaymentsSettings.loadPaypalSdk: script.src is: ', script.src)

      script.addEventListener('load', () => {
        window.paypal.Buttons({
          createOrder: (data, actions) => {
            return this.handleCreateOrder(data, actions)
          },
          onApprove: async (data, actions) => {
            this.handleOnApprove(data, actions)
          },
          onError: err => {
            console.log(err)
          }
        }).render(this.$refs.paypal)
      })
      document.head.appendChild(script)
    }, // loadPaypalSdk
    /* ----- handler methods ----- */
    async handleGeneralUpload () {
      try {
        // this.loading = true
        console.log('TRACE-REMOVE: PaymentsSettings.handleGeneralUpload: this.formData is: ',
          this.formData)
        console.log('TRACE-REMOVE: PaymentsSettings.handleGeneralUpload: this.selectedInst is: ',
          this.selectedInst)
        console.log('TRACE-REMOVE: PaymentsSettings.handleGeneralUpload: this.selectedInst is: ',
          { ...this.selectedInst,
            settings: {
              ...this.selectedInst.settings,
              maxPaymAmount: this.formData.maxPaymAmount,
              paymDisabled: this.formData.paymDisabled
            }
          })
        await this.accntDispatchFetchInst()
        await this.accntDispatchSaveInst(
          { ...this.selectedInst,
            settings: {
              ...this.selectedInst.settings,
              maxPaymAmount: this.formData.maxPaymAmount,
              paymDisabled: this.formData.paymDisabled
            }
          })
        // this.handleClear()
        this.$bvToast.toast('Upload success!')
      } catch (e) {
        this.$bvToast.toast('Error uploading Order settings!')
        console.error('PaymentsSettings.handleGeneralUpload: error is: ', e)
      } finally {
        // this.loading = false
      }
    }, // handleGeneralUpload
    async handleStartOnboarding () {
      console.log('TRACE-REMOVE: handleStartOnboarding: selectedPitTab is: ', this.formData.selectedPitTab)
      console.log('TRACE-REMOVE: handleStartOnboarding: selectedPit is: ', this.selectedPit)
      try {
        this.loading = true
        // await this.glapiCreateOnboarding({ instKey: this.selectedInst.instKey, pit: 'pit-paypal' })
        await this.glapiCreateOnboarding(
          { instKey: this.selectedInst.instKey, pit: this.selectedPit })
        await this.accntDispatchFetchInst()
        // window.location.href = this.selectedInst.settings.integrations.['pit-paypal'].onboarding.actionUrl.href
        window.location.href = this.selectedInst.settings.integrations.[this.selectedPit].onboarding.actionUrl.href
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
        console.log('TRACE-REMOVE: exception is: ', e)
      } finally {
        this.loading = false
      }
    }, // handleStartOnboarding
    async handleFinalizeOnboarding () {
      try {
        this.loading = true
        await this.glapiCompleteOnboarding(
          { instKey: this.selectedInst.instKey, pit: this.selectedPit })
        await this.accntDispatchFetchInst()
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
        console.log('TRACE-REMOVE: exception is: ', e)
      } finally {
        this.loading = false
      }
    }, // handleFinalizeOnboarding
    async handleStripePay () {
      // this.stripe.inst.createToken(this.stripe.card).then(function (result) {
      //   console.log('TRACE-REMOVE: PaymentsSettings.handleStripePay: result is: ', result)
      // })
      this.loading = true
      let _resp
      console.log('INFO: PaymentsSettings.handleStripePay: PO creation started!')
      try {
        _resp = await this.glapiCreatePaymentOrder({
          instKey: this.selectedInst.instKey,
          pit: 'pit-stripe',
          amount: this.formData.payAmount
        })
        console.log('INFO: PaymentsSettings.handleStripePay: PO created!')
        const result = await this.stripe.inst.confirmCardPayment(
          _resp.data.payload.paymentOrder.pmIntnClientSecret, {
            payment_method: {
              card: this.stripe.card,
              billing_details: {
                name: 'Geekline dashboard test payment'
              }
            }
          })
        console.log('TRACE-REMOVE: result is: ', result)
        if (result.error) {
          console.log('INFO: PaymentsSettings.handleStripePay: PO confirmation failed!')
          this.$bvToast.toast('An error occured! ' + result.error.message)
        } else {
          console.log('INFO: PaymentsSettings.handleStripePay: PO confirmed!')
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            console.log('INFO: PaymentsSettings.handleStripePay: PO success!')
            const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))
            let iter = 0
            let finalized = false
            while (!finalized && iter++ < 3) {
              try {
                await sleep(3000)
                await this.glapiUpdatePaymentOrder({
                  instKey: this.selectedInst.instKey,
                  pit: 'pit-stripe',
                  orderId: _resp.data.payload.paymentOrder.ppOrderId
                })
                console.log('INFO: PaymentsSettings.handleStripePay: PO finalized!')
                finalized = true
              } catch (e2) {
                console.log('ERROR: PaymentsSettings.handleStripePay: ' +
                  'Order still not finalized! Exception is: ', e2)
              }
            }
            if (finalized) {
              this.$bvToast.toast('The payment was confirmed!')
            } else {
              this.$bvToast.toast('The payment was NOT confirmed! Check the dashboard for details!')
            }
          }
        }
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
        console.log('TRACE-REMOVE: handleStripePay: exception is: ', e)
      } finally {
        this.loading = false
      }
    }, // handleStripePay
    async handleCreateOrder (data, actions) {
      let resp
      try {
        resp = await this.glapiCreatePaymentOrder({
          instKey: this.selectedInst.instKey,
          pit: 'pit-paypal',
          amount: this.formData.payAmount
        })
        console.log('TRACE-REMOVE: PaymentsSettings.handleCreateOrder: resp is: ', resp)
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
        console.log('TRACE-REMOVE: handleCreateOrder: exception is: ', e)
      }

      return resp.data.payload.paymentOrder.id
    }, // handleCreateOrder
    async handleOnApprove (data, actions) {
      let resp
      try {
        resp = await this.glapiUpdatePaymentOrder(
          { instKey: this.selectedInst.instKey, orderId: data.orderID })
        console.log('TRACE-REMOVE: PaymentsSettings.handleOnApprove: resp is: ', resp)
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
        console.log('TRACE-REMOVE: handleOnApprove: exception is: ', e)
      }
    }, // handleOnApprove
    handleTabsChange (ev) {
      console.log('TRACE-REMOVE: handleTabsChange: ev is: ', ev)
      this.formData.selectedPitTab = ev
    }, // handleTabsChange
    /* ----- utility methods ----- */
    getStripeOnboardingStatus () {
      let _ret = ONBOARDING_STATUS.notStarted
      if (this.selectedInst && this.selectedInst.settings &&
        this.selectedInst.settings.integrations &&
        this.selectedInst.settings.integrations['pit-stripe']) {
        const pitStripe = this.selectedInst.settings.integrations['pit-stripe']
        if (pitStripe.onboarding && pitStripe.onboarding.accountId) {
          _ret = ONBOARDING_STATUS.started
          if (pitStripe.merchantDetails && pitStripe.merchantDetails.charges_enabled) {
            _ret = ONBOARDING_STATUS.completed
          }
        }
      }
      return _ret
    }, // getStripeOnboardingStatus
    getStripeConnectedAccountId () {
      let _ret
      if (this.selectedInst && this.selectedInst.settings &&
        this.selectedInst.settings.integrations &&
        this.selectedInst.settings.integrations['pit-stripe']) {
        const pitStripe = this.selectedInst.settings.integrations['pit-stripe']
        if (pitStripe.onboarding && pitStripe.onboarding.accountId) {
          _ret = pitStripe.onboarding.accountId
        }
      }
      return _ret
    } // getStripeConnectedAccountId
  }, // methods
  components: {
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
</style>
