<template>
  <div v-if="!loaded" v-html="info" :style="infoStyle" class="cmpn" />
  <div v-else>
    <!--TODO: put a link for returning back to the payment settings or do redirect -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import {
  actions as glapiActionsNames,
  moduleNamespace as glapiModuleNSp } from '@/store/modules/glapi/names'

export default {
  name: 'onboarding-complete',
  version: '0.0.1',
  props: {}, // props
  components: {}, // components
  data () {
    return {
      loaded: false,
      instKey: null,
      pitType: '',
      info: '... loading content!',
      infoStyle: { 'text-align': 'left', color: 'black' },
      formData: {}
    }
  }, // data
  /* ----- vuejs hooks ----- */
  async mounted () {
    this.instKey = this.$route.params.instKey
    const _pitType = this.$route.params.pitType
    console.log('TRACE-REMOVE: this.$route.params is: ', this.$route.params)
    console.log('TRACE-REMOVE: this.$route.query is: ', this.$route.query)
    let _payload = null
    if (_pitType === 'pit-paypay') {
      if (this.$route.params.instKey && this.$route.query.merchantId &&
        this.$route.query.merchantIdInPayPal && this.$route.query.permissionsGranted &&
        this.$route.query.consentStatus && this.$route.query.productIntentId &&
        this.$route.query.isEmailConfirmed && this.$route.query.accountStatus) {
        _payload = {
          instKey: this.$route.params.instKey,
          pit: 'pit-paypal',
          merchantId: this.$route.query.merchantId,
          merchantIdInPayPal: this.$route.query.merchantIdInPayPal,
          permissionsGranted: this.$route.query.permissionsGranted,
          consentStatus: this.$route.query.consentStatus,
          productIntentId: this.$route.query.productIntentId,
          isEmailConfirmed: this.$route.query.isEmailConfirmed,
          accountStatus: this.$route.query.accountStatus
        }
      }
    } else if (_pitType === 'pit-stripe') {
      _payload = { pit: 'pit-stripe' }
    }
    // TODO: implement the cases of not full registration
    if (_payload !== null) {
      try {
        this.loading = true
        await this.glapiCompleteOnboarding(_payload)
        this.info = 'OK!'
      } catch (e) {
        this.info = 'Some gl server problem occured with your onboarding! Please call the support!'
        this.infoStyle = { color: 'red' }
      } finally {
        this.loading = false
      }
    } else {
      this.info = 'Some paypal problem occured with your onboarding! Please call the support!'
      this.infoStyle = { color: 'red' }
    }
  }, // mounted
  computed: {}, // computed
  watch: {}, // watch
  methods: {
    ...mapActions(glapiModuleNSp, {
      glapiCompleteOnboarding: glapiActionsNames.UPDATE_ONBOARDING_COMPLETE })
  } // methods
}
</script>

<style lang="scss" scoped>
.cmpn {
  margin-top: 85px;
}
</style>
