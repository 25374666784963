<template>
  <div>
    <b-form-group
      label-cols-md="1"
      label="Name:"
      label-align-md="right"
      label-for="name">
      <b-form-input id="name" v-model="formData.name" />
    </b-form-group>
    <b-form-group label-cols-md="1" label="Address:" label-align-md="right">
      <b-form-group></b-form-group>
      <b-form-group
        label-cols-md="1"
        label="Street(w. nr.):"
        label-align-md="right"
        label-for="str">
        <b-form-input id="str" v-model="formData.street" />
      </b-form-group>
      <b-form-group
        label-cols-md="1"
        label="City(w. zip):"
        label-align-md="right"
        label-for="city">
        <b-form-input id="city" v-model="formData.city" />
      </b-form-group>
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Tel.:"
      label-align-md="right"
      label-for="phone">
      <b-form-input id="phone" v-model="formData.phone" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="eMail:"
      label-align-md="right"
      label-for="email">
      <b-form-input id="email" v-model="formData.email" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Web:"
      label-align-md="right"
      label-for="web">
      <b-form-input id="web" v-model="formData.url" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Logo Url:"
      label-align-md="right"
      label-for="logoUrl">
      <b-form-input id="logoUrl" v-model="formData.logoUrl" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'gl-orga',
  version: '0.0.1',
  props: {
    initialData: {
      type: Object,
      default: () => {}
    } // initialData
  }, // props
  data () {
    return {
      formData: this.initialData ? Object.assign({}, this.initialData) : {}
    }
  }, // data
  /* ----- vujs hooks ----- */
  mounted () {
  }, // mounted
  /* ----- vujs observers ----- */
  watch: {
    initialData: function (nv) {
      this.formData = Object.assign({}, this.initialData)
    } // initialData
  } // watch
}
</script>

<style lang="scss" scoped>
</style>
