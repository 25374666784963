<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'instance key: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      header-class="text-center"
      title="instance settings:"
      style="background-color: lightblue; margin: 2px;">
      <b-form-group label-cols-sm="1" label="Payments:" label-for="id-payments-field"
        label-align-sm="right" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="id-payments-field"
          :disabled="!hasPaymInst"
          v-model="formData.settings.paymentsOption"
          :options="radioOptions"
          :aria-describedby="ariaDescribedby"
          button-variant="outline-primary"
          size="md"
          name="payments-field"
          buttons></b-form-radio-group>
      </b-form-group>
      <b-form-group label-cols-sm="1" label="Occasions list:"
        label-for="id-occasion-field" label-align-sm="right" v-slot="{ ariaDescribedby }">
        <b-input-group style="max-width: 600px;">
          <b-form-input
            id="id-occasion-field"
            v-model="formData.occasionEntered"
            placeholder="Enter another occasion option"
            @keyup.enter="handleAddOccasion()" />
          <b-input-group-append>
            <b-button
              variant="outline-info"
              @click="handleAddOccasion()">Add
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-list-group style="max-width: 600px;">
          <b-list-group-item
            v-for="(occ, ndx) in formData.settings.occasionsList"
            :key="ndx"
            class="d-flex justify-content-between align-items-center">
            <b-form-input v-if="hasPaymInst" class="w-25" type="number" min="0" max="1000"
              v-model="formData.paymentsListEntered[ndx]" @keyup.enter="handleEditPayments(ndx)"
              @blur="handleEditPayments(ndx)" />
            {{ occ }}
            <b-button
              variant="outline-info"
              @click="handleRemoveOccasion(ndx)">Remove
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleUpload()">Upload</b-button>
          <b-button @click="handleClear()">Clear</b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { mapState, mapActions } from 'vuex'

import {
  states as accountStNames,
  actions as accountActionNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'account-settings',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      instKey: '',
      instId: '',
      // inst: null,
      hasPaymInst: false,
      radioOptions: [
        { text: 'No', value: 'no' },
        { text: 'Optional', value: 'optional' },
        { text: 'Mandatory', value: 'mandatory' }
      ],
      formData: {
        occasionEntered: '',
        paymentsListEntered: [],
        settings: {
          paymentsOption: 'no',
          occasionsList: [],
          paymentsList: []
        }
      }
    }
  }, // data
  async mounted () {
    try {
      console.log('TRACE-REMOVE: ScheduleSettings.mounted: this.instances is: ', this.instances)
      this.loading = true
      this.instId = this.$route.params.instId
      this.instKey = this.selectedInst.instKey
      // this.hasPaymInst = false
      this.hasPaymInst = this.instances.find(e => e.instTypeCode === 'it-payments')?.
        settings.integrations.['pit-stripe']?.merchantDetails?.onboardingComplete
      this.initSettings()
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(accountModuleNSp, [[accountStNames.INSTANCES]]),
    selectedInst: function () {
      return this.instances.find(e => e._id === this.instId)
    }
  }, // computed
  methods: {
    /* ----- mapped action methods ----- */
    ...mapActions(accountModuleNSp,
      { accntDispatchSaveInst: accountActionNames.SAVE_INSTANCE }),
    /* ----- utility methods ----- */
    initSettings () {
      const _settings = this.formData.settings
      _settings.paymentsOption = this.selectedInst.settings.paymentsOption || 'no'
      _settings.occasionsList = this.selectedInst.settings.occasionsList?.slice(0) ?? []
      _settings.paymentsList = this.selectedInst.settings.paymentsList?.slice(0) ?? []
      this.formData.paymentsListEntered = _settings.paymentsList.slice(0)
    }, // initSettings
    /* ----- handler methods ----- */
    async handleUpload () {
      try {
        // this.loading = true
        console.log('TRACE-REMOVE: handleUpload: this.formData.settings is: ', this.formData.settings)
        await this.accntDispatchSaveInst(
          { instKey: this.selectedInst.instKey, settings: this.formData.settings })
        // this.handleClear()
        this.$bvToast.toast('Upload success!')
      } catch (e) {
        this.$bvToast.toast('Error uploading Order settings!')
        console.log('ERROR-REMOVE: AccountSettings.handleUpload: error is: ', e)
      } finally {
        // this.loading = false
      }
    }, // handleUpload
    handleRemoveOccasion (index) {
      this.formData.settings.occasionsList.splice(index, 1)
      this.formData.settings.paymentsList.splice(index, 1)
      this.formData.paymentsListEntered.splice(index, 1)
    }, // handleRemoveOccasion
    handleAddOccasion () {
      if (this.formData.occasionEntered && (this.formData.occasionEntered !== '')) {
        this.formData.settings.occasionsList.push(this.formData.occasionEntered)
        this.formData.occasionEntered = ''
        if (this.hasPaymInst) {
          this.formData.paymentsListEntered.push('0.00')
          this.formData.settings.paymentsList.push('0.00')
        }
      }
    }, // handleAddOccasion
    handleClear () {
      this.initSettings()
    }, // handleClear
    handleEditPayments (ndx) {
      const _payment = this.formData.paymentsListEntered[ndx]
      if (_payment && (_payment !== '')) {
        if (_payment < 0 || (_payment > 0 && _payment < 0.5) || _payment > 1000) {
          // this.formData.paymentsListEntered[ndx] = this.formData.settings.paymentsList[ndx]
          this.$set(this.formData.paymentsListEntered, ndx, this.formData.settings.paymentsList[ndx])
          this.$bvToast.toast('Only values 0 and between 0.5 and 1000 are alowed!')
        } else {
          this.$set(this.formData.paymentsListEntered, ndx, parseFloat(_payment).toFixed(2))
          this.formData.settings.paymentsList[ndx] = parseFloat(_payment).toFixed(2)
        }
      }
    } // handleAddAmount
    /* ----- utility methods ----- */
  }, // methods
  components: {
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
</style>
