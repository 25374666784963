import Vue from 'vue'

// import BootstrapVue from 'bootstrap-vue'
import {
  AlertPlugin,
  ButtonPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormSpinbuttonPlugin,
  FormTagsPlugin,
  FormTextareaPlugin,
  FormTimepickerPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  OverlayPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  BIcon,
  BIconArrow90degLeft,
  BIconArrow90degRight,
  BIconBlockquoteLeft,
  BIconCardChecklist,
  BIconCash,
  BIconCloudCheckFill,
  BIconCodeSquare,
  BIconDistributeVertical,
  BIconFacebook,
  BIconFileCodeFill,
  BIconFiles,
  BIconGlobe,
  BIconGraphUp,
  BIconImage,
  BIconInstagram,
  BIconLinkedin,
  BIconListOl,
  BIconListUl,
  BIconMap,
  BIconParagraph,
  BIconPhoneLandscapeFill,
  BIconServer,
  BIconShieldLockFill,
  BIconStopwatchFill,
  BIconTools,
  BIconTrash,
  BIconTypeBold,
  BIconTypeItalic,
  BIconTypeStrikethrough,
  BIconTypeUnderline,
  BIconUpload
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
/* Without it following styles are missing: toast, */
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Vue.use(BootstrapVue)
Vue.use(AlertPlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(CarouselPlugin)
Vue.use(CollapsePlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormSpinbuttonPlugin)
Vue.use(FormTagsPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormTimepickerPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(OverlayPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(ToastPlugin)

Vue.component('BIcon', BIcon)
Vue.component('BIconArrow90degLeft', BIconArrow90degLeft)
Vue.component('BIconArrow90degRight', BIconArrow90degRight)
Vue.component('BIconBlockquoteLeft', BIconBlockquoteLeft)
Vue.component('BIconCardChecklist', BIconCardChecklist)
Vue.component('BIconCash', BIconCash)
Vue.component('BIconCloudCheckFill', BIconCloudCheckFill)
Vue.component('BIconCodeSquare', BIconCodeSquare)
Vue.component('BIconDistributeVertical', BIconDistributeVertical)
Vue.component('BIconFacebook', BIconFacebook)
Vue.component('BIconFileCodeFill', BIconFileCodeFill)
Vue.component('BIconFiles', BIconFiles)
Vue.component('BIconGlobe', BIconGlobe)
Vue.component('BIconGraphUp', BIconGraphUp)
Vue.component('BIconImage', BIconImage)
Vue.component('BIconInstagram', BIconInstagram)
Vue.component('BIconLinkedin', BIconLinkedin)
Vue.component('BIconListOl', BIconListOl)
Vue.component('BIconListUl', BIconListUl)
Vue.component('BIconMap', BIconMap)
Vue.component('BIconParagraph', BIconParagraph)
Vue.component('BIconPhoneLandscapeFill', BIconPhoneLandscapeFill)
Vue.component('BIconServer', BIconServer)
Vue.component('BIconShieldLockFill', BIconShieldLockFill)
Vue.component('BIconStopwatchFill', BIconStopwatchFill)
Vue.component('BIconTools', BIconTools)
Vue.component('BIconTrash', BIconTrash)
Vue.component('BIconTypeBold', BIconTypeBold)
Vue.component('BIconTypeItalic', BIconTypeItalic)
Vue.component('BIconTypeStrikethrough', BIconTypeStrikethrough)
Vue.component('BIconTypeUnderline', BIconTypeUnderline)
Vue.component('BIconUpload', BIconUpload)
