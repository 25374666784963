<template>
  <div>
    <b-form-group
      label-cols-sm="1"
      label="Structured data (json-ld):"
      label-align-sm="right"
      label-for="structData">
      <b-form-textarea
        id="structData"
        v-model="formData.structData"
        placeholder="Enter some structured data ..."
        rows="2"
        max-rows="3" />
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Introduction:"
      label-align-sm="right"
      label-for="intro">
      <b-form-textarea
        id="intro"
        v-model="formData.intro"
        placeholder="Enter some introduction ..."
        rows="2"
        max-rows="3" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Image Key:"
      label-align-md="right"
      label-for="imgKey">
      <b-form-input
        id="imgKey"
        v-model="formData.imgKey"
        @blur="articleImputHandleBlur($event)"
        @keyup.enter="articleImputHandleBlur($event)" />
      <img
        id="articleImage"
        :src="formData.imgSrc"
        ref="articleImageRef"
        width= "320"
        height= "240" />
    </b-form-group>
    <gl-text title="Body:" ref="articleBodyRef" />
  </div>
</template>

<script>
import GlText from '@/components/content/GlText'

const NO_IMAGE_AVAILABLE = require('@/assets/content/no_img_available-2-320x240.jpg')
const __defaultFormData = {
  structData: '',
  intro: '',
  imgKey: null,
  imgSrc: NO_IMAGE_AVAILABLE,
  body: ''
}

export default {
  name: 'gl-article',
  version: '0.0.2',
  props: {
    initialData: {
      type: Object,
      default: () => {}
    }, // initialData
    findContentByKey: {
      type: Function
    } // findContentByKey
  }, // props
  data () {
    return {
      formData: this.initialData ? Object.assign({}, this.initialData) : Object.assign({}, __defaultFormData)
    }
  }, // data
  /* ----- imported components ----- */
  components: {
    GlText
  }, // components
  /* ----- vuejs hooks ----- */
  mounted () {
    if (this.formData.body && this.formData.body !== '') {
      this.$refs.articleBodyRef.setContent(this.formData.body)
    }
  }, // mounted
  /* ----- vuejs observers ----- */
  watch: {
    initialData: function (nv) {
      console.log('TRACE-REMOVE: GlArticle.watch.initialData: this.initialData is: ', this.initialData)
      console.log('TRACE-REMOVE: GlArticle.watch.initialData: nv is: ', nv)
      this.formData = Object.assign({}, this.initialData)
      if (this.formData.body && this.formData.body !== '') {
        this.$refs.articleBodyRef.setContent(this.formData.body)
      }
    } // initialData
  }, // watch
  methods: {
    /* ----- public methods ----- */
    getFormData () {
      this.formData.body = this.$refs.articleBodyRef.getJSON()
      return this.formData
    }, // getFormData
    /* ----- handler methods ----- */
    articleImputHandleBlur (ev) {
      if (this.findContentByKey) {
        const _imageContentFound = this.findContentByKey(ev.target.value)
        if (_imageContentFound) {
          this.formData.imgSrc = _imageContentFound.fragments.imgUrl
        } else {
          this.formData.imgSrc = NO_IMAGE_AVAILABLE
        }
      } else {
        this.formData.imgSrc = NO_IMAGE_AVAILABLE
      }
    } // articleImputHandleBlur
  } // methods
}
</script>

<style scoped>
</style>
