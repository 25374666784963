<template>
  <b-form-group
    label-cols-md="1"
    :label="title"
    label-align-md="right"
    label-for="body">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold">
          <!-- <i class="mdi mdi-format-bold" /> -->
          <b-icon icon="type-bold" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">bold</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic">
          <!-- <i class="mdi mdi-format-italic" /> -->
          <b-icon icon="type-italic" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">italic</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike">
          <!-- <i class="mdi mdi-format-strikethrough" /> -->
          <b-icon icon="type-strikethrough" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">strike</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline">
          <!-- <i class="mdi mdi-format-underline" /> -->
          <b-icon icon="type-underline" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">underline</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code">
          <!-- <i class="mdi mdi-code-tags" /> -->
          <b-icon icon="code-square" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">code</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph">
          <!-- <i class="mdi mdi-format-pilcrow" /> -->
          <b-icon icon="paragraph" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">paragraph</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })">
          H1
          <span class="gl-ttip-txt">Header 1</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })">
          H2
          <span class="gl-ttip-txt">Header 2</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })">
          H3
          <span class="gl-ttip-txt">Header 3</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list">
          <!-- <i class="mdi mdi-format-list-bulleted" /> -->
          <b-icon icon="list-ul" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">bullet list</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list">
          <!-- <i class="mdi mdi-format-list-numbered" /> -->
          <b-icon icon="list-ol" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">ordered list</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote">
          <!-- <i class="mdi mdi-format-quote-close" /> -->
          <b-icon icon="blockquote-left" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">blockquote</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block">
          <!-- <i class="mdi mdi-code-string" /> -->
          <b-icon icon="file-code-fill" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">code block</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          @click="commands.horizontal_rule">
          <!-- <i class="mdi mdi-border-horizontal" /> -->
          <b-icon icon="distribute-vertical" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">horizontal rule</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          @click="commands.undo">
          <!-- <i class="mdi mdi-undo" /> -->
          <b-icon icon="arrow90deg-left" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">undo</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          @click="commands.redo">
          <!-- <i class="mdi mdi-redo" /> -->
          <b-icon icon="arrow90deg-right" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">redo</span>
        </button>
        <button
          class="menubar__button gl-ttip"
          @click="showImagePrompt(commands.image)">
          <!-- <i class="mdi mdi-image" /> -->
          <b-icon icon="image" font-scale="1"></b-icon>
          <span class="gl-ttip-txt">image</span>
        </button>
      </div>
    </editor-menu-bar>
    <editor-menu-bubble
      class="menububble"
      :editor="editor"
      @hide="hideLinkBubble"
      v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">
        <form
          class="menububble__form"
          v-if="linkBubbleIsActive"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input
            class="menububble__input"
            type="text"
            v-model="linkUrl"
            placeholder="https://"
            ref="linkInput"
            @keydown.esc="hideLinkBubble"/>
          <button
            class="menububble__button"
            @click="setLinkUrl(commands.link, null)"
            type="button">
            <i class="mdi mdi-link-variant-remove" />
          </button>
        </form>
        <template v-else>
          <button
            class="menububble__button"
            @click="showLinkBubble(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }">
            <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
            <i class="mdi mdi-link-variant-plus" />
          </button>
        </template>
      </div>
    </editor-menu-bubble>
    <editor-content
      :editor="editor"
      class="editor editor__content"
      style="background-color: white; overflow-y: scroll; height: 150px;" />
  </b-form-group>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import { Bold, Italic, Strike, Underline, Code, Heading, BulletList, OrderedList,
  Blockquote, CodeBlock, HorizontalRule, ListItem, History, Link, Image, Placeholder }
  from 'tiptap-extensions'

export default {
  name: 'gl-text',
  version: '0.0.1',
  props: {
    title: {
      type: String,
      default: ''
    }
  }, // props
  data () {
    return {
      editor: null,
      linkUrl: null,
      linkBubbleIsActive: false
    }
  }, // data
  /* ----- vuejs hooks ----- */
  mounted () {
    this.editor = new Editor({
      // onFocus: this.handleEditorFocus,
      // onBlur: this.handleEditorBlur,
      extensions: [
        new Bold(),
        new Italic(),
        new Strike(),
        new Underline(),
        new Code(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(), /* for the lists -> BulletList, OrderedList, etc. */
        new BulletList(),
        new OrderedList(),
        new Blockquote(),
        new CodeBlock(),
        new HorizontalRule(),
        new History(),
        new Link(),
        new Image(),
        new Placeholder({
          autoFocus: true,
          showOnlyCurrent: false,
          emptyNodeText: () => {
            return 'Please enter some content ...'
          }
        })
      ]
    })
  }, // mounted
  methods: {
    /* ----- public methods ----- */
    getJSON () {
      return this.editor.getJSON()
    }, // getJSON
    hasContent () {
      console.log('TRACE-REMOVE: ClText.hasContent-1: this.editor.getJSON is: ', this.editor.getJSON())
      console.log('TRACE-REMOVE: ClText.hasContent-1: this.editor.getHTML is: ', this.editor.getHTML())
      console.log('TRACE-REMOVE: ClText.hasContent-1: this.editor.getHTML === paragrapsh is: ', (this.editor.getHTML() === '<p></p>'))
      // if (this.editor && this.editor.content && this.editor.content !== '' && this.editor.content !== 'Enter your content here!') {
      if (this.editor && this.editor.getHTML() !== '<p></p>') {
        console.log('TRACE-REMOVE: ClText.hasContent-2: this.editor is: ', this.editor)
        return true
      }
      console.log('TRACE-REMOVE: ClText.hasContent-3: this.editor is: ', this.editor)
      return false
    }, // hasContent
    clearContent () {
      this.editor.setContent('')
    }, // clearContent
    setContent (c) {
      this.editor.setContent(c)
    }, // setContent
    /* ----- private methods ----- */
    showLinkBubble (attrs) {
      this.linkUrl = attrs.href
      this.linkBubbleIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    }, // showLinkBubble
    hideLinkBubble () {
      this.linkUrl = null
      this.linkBubbleIsActive = false
    }, // hideLinkBubble
    setLinkUrl (command, url) {
      command({ href: url })
      this.hideLinkBubble()
    }, // setLinkUrl
    showImagePrompt (command) {
      const src = prompt('Please enter the url of your image here:')
      if (src !== null) {
        command({ src })
      }
    }, // showImagePrompt
    beforeDestroy () {
      this.editor.destroy()
    } // beforeDestroy
  }, // methods
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble
  } // components
}
</script>

<style lang="scss">
@import "@/assets/style/scss/main.scss";
@import "@/assets/3rdparty/tiptap/editor.scss";
@import "@/assets/3rdparty/tiptap/menubar.scss";
@import "@/assets/3rdparty/tiptap/menububble.scss";

.editor *.is-empty:nth-child(1)::before{
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
.editor {
  margin: 0 auto 1rem auto;
}
</style>
