structData<template>
  <div>
    <b-form-group
      label-cols-sm="1"
      label="Structured data (json-ld):"
      label-align-sm="right"
      label-for="structData">
      <b-form-textarea
        id="structData"
        v-model="formData.structData"
        placeholder="Enter some structured data ..."
        rows="2"
        max-rows="3" />
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Introduction:"
      label-align-sm="right"
      label-for="intro">
      <b-form-textarea
        id="intro"
        v-model="formData.intro"
        placeholder="Enter some introduction ..."
        rows="2"
        max-rows="3" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label=" / List order:"
      label-align-md="right"
      label-for="tags">
      <b-form-row>
        <b-col md="6">
          <b-form-tags id="tags" v-model="formData.tags" ref="tagsRef" :limit="5"
            :tag-validator="isValidTag" placeholder="Enter up to 20 chars, separated by space / enter"
            separator=" " tag-pills tag-variant="primary" remove-on-delete></b-form-tags>
        </b-col>
        <b-col md="6">
          <b-form-input id="listOrder" v-model="formData.listOrder" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Image Key:"
      label-align-md="right"
      label-for="imgKey">
      <b-form-input
        id="imgKey"
        v-model="formData.imgKey"
        @blur="blogPostImputHandleBlur($event)"
        @keyup.enter="blogPostImputHandleBlur($event)" />
      <img
        id="blogPostImage"
        :src="formData.imgSrc"
        ref="blogPostImageRef"
        width= "320"
        height= "240" />
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Multi-language / Token / Language:"
      label-for="mulang"
      v-slot="{ ariaDescribedby }">
      <b-form-row>
        <b-col md="1">
          <b-form-checkbox
            id="mulang"
            v-model="formData.mulang"
            name="mulang">
            Allow
          </b-form-checkbox>
        </b-col>
        <b-col md="4">
          <b-form-input
            id="mulangToken"
            placeholder="Multi-language Token (mulangToken)"
            v-model="formData.mulangToken"
            :disabled="!formData.mulang" />
        </b-col>
        <b-col md="4">
          <b-form-select
            id="langLocale"
            v-model="formData.langLocale"
            :options="langLocaleOptions"
            :disabled="!formData.mulang" />
        </b-col>
      </b-form-row>
    </b-form-group>
    <gl-text title="Body:" ref="blogPostBodyRef" />
  </div>
</template>

<script>
import GlText from '@/components/content/GlText'
import { LANGUAGE_LOCALE_DEFS } from '@/services/i18n/constants'

const NO_IMAGE_AVAILABLE = require('@/assets/content/no_img_available-2-320x240.jpg')
const __defaultFormData = {
  structData: '',
  intro: '',
  tags: [],
  listOrder: 0,
  imgKey: null,
  imgSrc: NO_IMAGE_AVAILABLE,
  mulang: false,
  mulangToken: null,
  mulangDefault: false,
  langLocale: LANGUAGE_LOCALE_DEFS.ENGLISH,
  body: ''
}

export default {
  name: 'gl-blog-post',
  version: '0.0.3',
  props: {
    initialData: {
      type: Object,
      default: () => {}
    }, // initialData
    findContentByKey: {
      type: Function
    } // findContentByKey
  }, // props
  data () {
    return {
      langLocaleOptions: Object.keys(LANGUAGE_LOCALE_DEFS)
        .map(e => ({ value: LANGUAGE_LOCALE_DEFS[e], text: e })),
      formData: this.initialData ? Object.assign({}, this.initialData) : Object.assign({}, __defaultFormData)
    }
  }, // data
  /* ----- imported components ----- */
  components: {
    GlText
  }, // components
  /* ----- vuejs hooks ----- */
  mounted () {
    if (this.formData.body && this.formData.body !== '') {
      this.$refs.blogPostBodyRef.setContent(this.formData.body)
    }
  }, // mounted
  /* ----- vuejs observers ----- */
  watch: {
    initialData: function (nv) {
      this.formData = Object.assign({}, this.initialData)
      if (this.formData.body && this.formData.body !== '') {
        this.$refs.blogPostBodyRef.setContent(this.formData.body)
      }
    } // initialData
  }, // watch
  methods: {
    /* ----- public methods ----- */
    getFormData () {
      this.formData.body = this.$refs.blogPostBodyRef.getJSON()
      return this.formData
    }, // getFormData
    /* ----- handler methods ----- */
    blogPostImputHandleBlur (ev) {
      if (this.findContentByKey) {
        const _imageContentFound = this.findContentByKey(ev.target.value)
        if (_imageContentFound) {
          this.formData.imgSrc = _imageContentFound.fragments.imgUrl
        } else {
          this.formData.imgSrc = NO_IMAGE_AVAILABLE
        }
      } else {
        this.formData.imgSrc = NO_IMAGE_AVAILABLE
      }
    }, // blogPostImputHandleBlur
    getLangLocaleOptions () {
      console.log('TRACE-REMOVE: LANGUAGE_LOCALE_DEFS.keys is: ', Object.keys(LANGUAGE_LOCALE_DEFS))
      return Object.keys(LANGUAGE_LOCALE_DEFS)
        .map(e => ({ value: LANGUAGE_LOCALE_DEFS[e], text: e }))
    }
  } // methods
}
</script>

<style scoped>
</style>
