<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'manage payments instance: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <!-- <b-card
      header-class="text-center"
      title="Payments Order:"
      style="background-color: lightblue; margin: 2px;">
      <gl-order v-if="orderLoaded" ref="glOrderRef"
        :initialData="{...orderDetails._id, cpd: orderDetails.cpd}"/>
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleUpload()">Upload</b-button>
          <b-button @click="handleClear()">Clear</b-button>
        </div>
      </template>
    </b-card> -->
    <b-card
      header-class="text-center"
      title="All Payment Orders"
      style="background-color: lightblue; margin: 2px;">
      <b-col lg="7" class="mb-2" style="padding: 0px;">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="formData.table.filter"
            type="search"
            placeholder="Filter on all the fields. Use &amp; to combine terms.">
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!formData.table.filter" @click="formData.table.filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-table
        sticky-header
        :fields="fieldsDefs"
        :items="paymOrders"
        :filter="formData.table.filter"
        :filter-function="customFilterFn"
        :busy="loading">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(status)="data">
          <p :style="getStatusStyle(data.item.status)">{{ data.item.status }}</p>
        </template>
        <template v-slot:cell(actions)="data">
          <!-- <a @click="handleLoadOrder(data.item)" class="gl-ttip">
            <b-icon icon="upload" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">load</span>
          </a> -->
          <a @click="handleCopyOrderKey(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="files" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">copy key</span>
          </a>
          <a @click="handleInvalidateOrder(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="trash" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">invalidate</span>
          </a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { mapState } from 'vuex'
import * as layoutSvc from '@/services/utils/layout'

import { actions as glapiActionsNames,
  states as glapiStNames,
  moduleNamespace as glapiModuleNSp,
  getNamespacedName } from '@/store/modules/glapi/names'

import {
  states as accountStNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'manage-inst-orders',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      orderLoaded: false,
      // TODO: all form data in one formData object
      instKey: '',
      formData: {
        glapiId: '',
        table: {
          filter: ''
        }
      },
      fieldsDefs: [
        {
          key: 'key',
          label: 'PaymOrd key'
        },
        {
          key: 'purchaseOrder.ordersKey',
          label: 'PurchOrd key'
        },
        {
          key: 'ppOrder.id',
          label: 'PP id'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'ppOrder.amount',
          label: 'Amount'
        },
        {
          key: 'createDate',
          label: 'Create date'
        },
        {
          key: 'actions',
          label: 'Actions',
          stickyColumn: true
        }
      ]
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      // await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_CONTENT))
      const _foundInst = this.instances.find(e => e._id === this.$route.params.instId)
      if (_foundInst) {
        this.instKey = _foundInst.instKey
        this.formData.contentKey = _foundInst.contentInstRefKey
      } else {
        // TODO: consider route to the dashboard or error page
        // console.log('WARN-REMOVE: no key found for the instId: ', this.$route.params.instId)
      }
      await this.$store.dispatch(getNamespacedName(
        glapiActionsNames.FETCH_PAYM_ORDERS), [this.instKey, 'pit-all'])
      console.log('TRACE-REMOVE: ManageinstPaymOrders.mounted: this.paymOrders is: ', this.paymOrders)
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      // console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(glapiModuleNSp, [[glapiStNames.PAYM_ORDERS]]),
    ...mapState(glapiModuleNSp, [[glapiStNames.ORDER_DETAILS]]),
    ...mapState(accountModuleNSp, [[accountStNames.INSTANCES]])
  }, // computed
  methods: {
    /* ----- handler methods ----- */
    async handleCopyOrderKey (item) {
      try {
        await navigator.clipboard.writeText(item.key)
        this.$bvToast.toast('Content key copied in clipboard!')
      } catch (e) {
        this.$bvToast.toast('Error copying the content key in clipboard!')
      }
    }, // handleCopyOrderKey
    /* ----- utility methods ----- */
    mapCatalog2Select: layoutSvc.mapCatalog2Select,
    findContentByKey (k) {
      return this.content.find(el => el.key === k)
    }, // findContentByKey
    getStatusStyle (status) {
      let _ret = 'color: blue'
      if (status === 'finalized') {
        _ret = 'color: green'
      }
      return _ret
    }, // getStatusStyle
    customFilterFn (rec) {
      // console.log('TRACE-REMOVE: ManageinstPaymOrders.customFilterFn: rec is: ', rec)
      let _purchaseOrder = ''
      if (rec['purchaseOrder']) {
        _purchaseOrder = rec['purchaseOrder']
      }
      const recStr = JSON.stringify(rec['key']) +
        JSON.stringify(_purchaseOrder['ordersKey']) +
        JSON.stringify(rec['ppOrder']['id']) +
        JSON.stringify(rec['status']) +
        JSON.stringify(rec['ppOrder']['amount']) +
        JSON.stringify(rec['createDate'])
      let _matched = true
      if (this.formData.table.filter) {
        for (const match of this.formData.table.filter.split('&')) {
          if (!JSON.stringify(recStr).includes(match)) {
            _matched = false
            break
          }
        }
      }
      return _matched
    } // customFilterFn
  }, // methods
  components: {} // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
.cmpn-actions {
  padding-left: 10px;
}
</style>
<style lang="scss">
@import "@/assets/style/scss/main.scss";
</style>
