<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'instance key: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      header-class="text-center"
      title="instance settings:"
      style="background-color: lightblue; margin: 2px;">
      <b-form-group label="email notifications:">
        <b-form-radio-group
          v-model="formData.settings.emailNotif"
          name="emailNotif"
          buttons
          button-variant="primary">
          <b-form-radio value="none">none</b-form-radio>
          <b-form-radio value="first">first</b-form-radio>
          <b-form-radio value="every">every</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleUpload()">Upload</b-button>
          <b-button @click="handleClear()">Clear</b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { mapState, mapActions } from 'vuex'

import {
  states as accountStNames,
  actions as accountActionNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'account-settings',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      instKey: '',
      instId: '',
      // inst: null,
      formData: {
        settings: {
          emailNotif: 'none'
        }
      }
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      this.instId = this.$route.params.instId
      // const _foundInst = this.instances.find(e => e._id === this.$route.params.instId)
      const _foundInst = this.selectedInst
      if (_foundInst) {
        // this.inst = _foundInst
        this.instKey = _foundInst.instKey
        this.formData.contentKey = _foundInst.contentInstRefKey
        this.initSettings()
      } else {
        // TODO: consider route to the dashboard or error page
        // console.log('WARN-REMOVE: no key found for the instId: ', this.$route.params.instId)
      }
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(accountModuleNSp, [[accountStNames.INSTANCES]]),
    selectedInst: function () {
      return this.instances.find(e => e._id === this.instId)
    }
  }, // computed
  methods: {
    /* ----- mapped action methods ----- */
    ...mapActions(accountModuleNSp,
      { accntDispatchSaveInst: accountActionNames.SAVE_INSTANCE }),
    /* ----- utility methods ----- */
    initSettings () {
      const _foundInst = this.selectedInst
      if (_foundInst.settings && _foundInst.settings.emailNotif) {
        this.formData.settings = Object.assign({}, this.selectedInst.settings)
      } else {
        this.formData.settings.emailNotif = 'none'
      }
    }, // initSettings
    /* ----- handler methods ----- */
    async handleUpload () {
      try {
        // this.loading = true
        await this.accntDispatchSaveInst(
          { instKey: this.selectedInst.instKey, settings: this.formData.settings })
        // this.handleClear()
        this.$bvToast.toast('Upload success!')
      } catch (e) {
        this.$bvToast.toast('Error uploading Order settings!')
        console.log('ERROR-REMOVE: AccountSettings.handleUpload: error is: ', e)
      } finally {
        // this.loading = false
      }
    }, // handleUpload
    handleClear () {
      this.initSettings()
    } // handleClear
    /* ----- utility methods ----- */
  }, // methods
  components: {
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
</style>
