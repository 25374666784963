<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      :title="'manage instance: ' + instKey"
      style="background-color: lightblue; margin: 2px;">
    </b-card>
    <b-card
      header-class="text-center"
      title="Order:"
      style="background-color: lightblue; margin: 2px;">
      <gl-order v-if="orderLoaded && isPurchaseOrder" ref="glOrderRef"
        :initialData="{...orderDetails._id, cpd: orderDetails.cpd}"/>
      <gl-application-order v-if="orderLoaded && isApplicationOrder"
        ref="glOrderRef" :initialData="orderDetails"/>
      <template v-slot:footer>
        <div style="display: flex; flex-flow: row wrap; justify-content: space-around;">
          <b-button @click="handleUpload()">Upload</b-button>
          <b-button @click="handleClear()">Clear</b-button>
        </div>
      </template>
    </b-card>
    <b-card
      header-class="text-center"
      title="All Orders"
      style="background-color: lightblue; margin: 2px;">
      <b-col lg="7" class="mb-2" style="padding: 0px;">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="formData.table.filter"
            type="search"
            placeholder="Filter on all the fields. Use &amp; to combine terms.">
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!formData.table.filter" @click="formData.table.filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-table
        sticky-header
        :fields="fieldsDefs"
        :items="orders"
        :filter="formData.table.filter"
        :filter-function="customFilterFn"
        :busy="loading">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(customer)="data">
          {{ getCustomerName(data.item.deliveryDetails, data.item.applicationDetails) }}
        </template>
        <template v-slot:cell(confirmedSent)="data">
          {{ data.item.confirmed }} / {{ data.item.sent }}
        </template>
        <template v-slot:cell(actions)="data">
          <a @click="handleLoadOrder(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="upload" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">load</span>
          </a>
          <a @click="handleCopyOrderKey(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="files" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">copy key</span>
          </a>
          <a @click="handleInvalidateOrder(data.item)" class="gl-ttip cmpn-actions">
            <b-icon icon="trash" font-scale="1"></b-icon>
            <span class="gl-ttip-txt">invalidate</span>
          </a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-dash'
import { mapState } from 'vuex'
// import GlOrder from '@/components/orders/GlOrder'
import * as layoutSvc from '@/services/utils/layout'

import { actions as glapiActionsNames,
  states as glapiStNames,
  moduleNamespace as glapiModuleNSp,
  getNamespacedName } from '@/store/modules/glapi/names'

import {
  states as accountStNames,
  moduleNamespace as accountModuleNSp } from '@/store/modules/account/names'

export default {
  name: 'manage-inst-orders',
  version: '0.0.1',
  data () {
    return {
      loading: false,
      orderLoaded: false,
      // TODO: all form data in one formData object
      instKey: '',
      formData: {
        glapiId: '',
        table: {
          filter: ''
        }
      },
      fieldsDefs: [
        {
          key: 'customer',
          label: 'Customer'
        },
        {
          key: 'key',
          label: 'Key'
        },
        {
          key: 'basketTotal',
          label: 'Total'
        },
        {
          key: 'confirmedSent',
          label: 'Conf. / Sent(Rej.)'
        },
        {
          key: 'createDate',
          label: 'Create date'
        },
        {
          key: 'actions',
          label: 'Actions',
          stickyColumn: true
        }
      ]
    }
  }, // data
  async mounted () {
    try {
      this.loading = true
      // await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_CONTENT))
      const _foundInst = this.instances.find(e => e._id === this.$route.params.instId)
      if (_foundInst) {
        this.instKey = _foundInst.instKey
        this.formData.contentKey = _foundInst.contentInstRefKey
      } else {
        // TODO: consider route to the dashboard or error page
        // console.log('WARN-REMOVE: no key found for the instId: ', this.$route.params.instId)
      }
      await this.$store.dispatch(getNamespacedName(glapiActionsNames.FETCH_ORDERS), this.instKey)
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      // console.log('TRACE-REMOVE: exception is: ', e)
    } finally {
      this.loading = false
    }
  }, // mounted
  computed: {
    ...mapState(glapiModuleNSp, [[glapiStNames.ORDERS]]),
    ...mapState(glapiModuleNSp, [[glapiStNames.ORDER_DETAILS]]),
    ...mapState(accountModuleNSp, [[accountStNames.INSTANCES]]),
    isPurchaseOrder: function () {
      // INFO: No type defaults to purchase order!
      return !this.orderDetails.type || this.orderDetails.type === 'ot-purchase'
    },
    isApplicationOrder: function () {
      return this.orderDetails.type && this.orderDetails.type === 'ot-application'
    }
  }, // computed
  methods: {
    async handleUpload () {
      if (!this.orderLoaded) {
        return
      }
      try {
        this.loading = true
        const _payload = {}
        if (this.isPurchaseOrder) {
          _payload.params = [this.instKey, this.orderDetails._id.key]
          _payload.orderDetails = { 'confirmed': false, 'sent': false }
          if (this.$refs.glOrderRef.formData.confirmed) {
            _payload.orderDetails.confirmed = this.$refs.glOrderRef.formData.confirmed
          }
          if (this.$refs.glOrderRef.formData.sent) {
            _payload.orderDetails.sent = this.$refs.glOrderRef.formData.sent
          }
        } else if (this.isApplicationOrder) {
          _payload.params = [this.instKey, this.orderDetails.key]
          _payload.orderDetails = { 'confirmed': false, 'rejected': false }
          if (this.$refs.glOrderRef.formData.confirmed) {
            _payload.orderDetails.confirmed = this.$refs.glOrderRef.formData.confirmed
          }
          if (this.$refs.glOrderRef.formData.rejected) {
            _payload.orderDetails.rejected = this.$refs.glOrderRef.formData.rejected
          }
        }
        await this.$store.dispatch(getNamespacedName(glapiActionsNames.UPLOAD_ORDER_DETAILS),
          _payload)
        this.handleClear()
        this.$bvToast.toast('Orders successfully uploaded!')
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
      } finally {
        this.loading = false
      }
    }, // handleUpload
    handleClear () {
      this.orderLoaded = false
    }, // handleClear
    async handleLoadOrder (orderItem) {
      try {
        await this.$store.dispatch(getNamespacedName(
          glapiActionsNames.FETCH_ORDER_DETAILS), [this.instKey, orderItem.key])
        this.orderLoaded = true
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
      }
    }, // handleLoadOrder
    async handleInvalidateOrder (contentItem) {
      // TODO: to be implemented
      // try {
      //   this.loading = true
      //   await this.$store.dispatch(getNamespacedName(glapiActionsNames.ERASE_CONTENT), contentItem)
      //   this.$bvToast.toast('The content was succesfully deleted!')
      // } catch (e) {
      //   this.$bvToast.toast(e.name + ': ' + e.message)
      // } finally {
      //   this.loading = false
      // }
    }, // handleInvalidateOrder
    async handleCopyOrderKey (item) {
      try {
        await navigator.clipboard.writeText(item.key)
        this.$bvToast.toast('Content key copied in clipboard!')
      } catch (e) {
        this.$bvToast.toast('Error copying the content key in clipboard!')
      }
    }, // handleCopyOrderKey
    /* ----- utility methods ----- */
    mapCatalog2Select: layoutSvc.mapCatalog2Select,
    findContentByKey (k) {
      return this.content.find(el => el.key === k)
    }, // findContentByKey
    customFilterFn (rec) {
      // console.log('TRACE-REMOVE: customFilterFn: rec is: ', rec)
      let recStr
      if (rec.deliveryDetails) {
        recStr = JSON.stringify(rec['deliveryDetails']['name']) +
          JSON.stringify(rec['key']) +
          JSON.stringify(rec['basketTotal']) +
          JSON.stringify(rec['confirmed']) + JSON.stringify(rec['sent']) +
          JSON.stringify(rec['createDate'])
      } else if (rec.applicationDetails) {
        recStr = JSON.stringify(rec['applicationDetails']['name']) +
          JSON.stringify(rec['key']) +
          JSON.stringify(rec['confirmed']) + JSON.stringify(rec['rejected']) +
          JSON.stringify(rec['createDate'])
      }
      let _matched = true
      if (this.formData.table.filter) {
        for (const match of this.formData.table.filter.split('&')) {
          if (!JSON.stringify(recStr).includes(match)) {
            _matched = false
            break
          }
        }
      }
      return _matched
    }, // customFilterFn
    getCustomerName (first, second) {
      return first?.name || second?.name
    }
  }, // methods
  components: {
    GlOrder: () => import('@/components/orders/GlOrder'),
    GlApplicationOrder: () => import('@/components/orders/GlApplicationOrder')
  } // components
}
</script>
<style scoped>
.cmpn {
  margin-top: 85px;
}
.cmpn-actions {
  padding-left: 10px;
}
</style>
<style lang="scss">
@import "@/assets/style/scss/main.scss";
</style>
